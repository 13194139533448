<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2">{{ menuTitle }}</h3>
          </template>
          <crud
            :crudSettings="crudSettings"
            :headers="
              walikota
                ? headersTahunan_
                : itemsTahunan.eselon == 2
                ? headersJPT
                : headersTahunan
            "
            :responseData="itemsTahunan"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            :dataLoading="loadingTahunan"
            :hideFooter="true"
            @onTableChange="updateTableTahunan"
            @onAssignmentClick="assignmentItem"
            @onLampiranAngkaKreditClick="lampiranAngkaKreditItem"
          >
            <template v-slot:append-header>
              <v-spacer></v-spacer>
              <template>
                <div v-if="tahun == 2022">
                  <v-tooltip
                    top
                    v-if="
                      (itemsTahunan.eselon == 2 ||
                        itemsTahunan.eselon == 4 ||
                        itemsTahunan.eselon == 3) &&
                      itemsTahunan.periode_sync_skp_tahunan
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="cyan darken-4"
                        @click="syncSakip"
                        :loading="loadingBtn.syncBtn"
                        class="mx-1"
                        small
                        dark
                        ><v-icon small left>mdi-sync</v-icon></v-btn
                      >
                    </template>
                    <span>Sinkronisasi SKP Dengan E-Sakip</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-if="
                      itemsTahunan.periode_input_skp ||
                      itemsTahunan.periode_input_jabatan_baru ||
                      itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                      enableAll ||
                      walikota ||
                      itemsTahunan.is_kode_skpd_listed_tambah_tahunan
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="green"
                        @click="openDialogFormatSKP()"
                        small
                        dark
                        class="mx-1"
                        ><v-icon small left>mdi-plus</v-icon></v-btn
                      >
                    </template>
                    <span>Tambah SKP</span>
                  </v-tooltip>
                  <v-tooltip top v-if="walikota == false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        v-bind:color="
                          rowData.length > 0 ? 'success' : 'info darken-3'
                        "
                        @click="dialogLampiranSKP = true"
                        small
                        dark
                        class="mx-1"
                        ><v-icon small left>mdi-attachment</v-icon></v-btn
                      >
                    </template>
                    <span> Isi Lampiran SKP</span>
                  </v-tooltip>
                  <v-tooltip top v-if="walikota == false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                        @click="lihatFormEkspektasiPerilaku()"
                        small
                        dark
                        class="mx-1"
                        ><v-icon small left>mdi-account-check</v-icon></v-btn
                      >
                    </template>
                    <span>Lihat Ekspektasi Pimpinan</span>
                  </v-tooltip>
                  <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind:color="rowData.length > 0 ? 'success' : 'info'" v-bind="attrs" v-on="on" 
                    @click="isiFormEkspektasiPerilaku()"
                    :disabled="disabled_ekskpektasi"
                    small dark class="mx-1"><v-icon small left>mdi-account-multiple</v-icon></v-btn>
                </template>
                <span>Isi Ekspektasi Perilaku Kerja</span>
              </v-tooltip> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="dialogTutupSKP = true"
                        color="red"
                        class="mx-1"
                        small
                        dark
                        ><v-icon small left>mdi-door</v-icon></v-btn
                      >
                    </template>
                    <span>Tutup SKP</span>
                  </v-tooltip>
                </div>
                <div v-else-if="tahun >= 2023">
                  <v-tooltip
                    top
                    v-if="
                      ((itemsTahunan.eselon == 2 || itemsTahunan.kepala_upt) &&
                        itemsTahunan.periode_sync_skp_tahunan) ||
                      itemsTahunan.buka_sync_skp_tahunan_by_nip
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="cyan darken-4"
                        @click="syncSakip"
                        :loading="loadingBtn.syncBtn"
                        class="mx-1"
                        small
                        dark
                        >Sinkronisasi &nbsp;<v-icon small left
                          >mdi-sync</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Sinkronisasi SKP Dengan E-Sakip</span>
                  </v-tooltip>
                  <!-- <v-tooltip top v-if="(itemsTahunan.eselon == 2) && (itemsTahunan.periode_input_skp || itemsTahunan.periode_input_jabatan_baru || itemsTahunan.buka_tambah_skp_tahunan_by_nip || enableAll || walikota || itemsTahunan.is_kode_skpd_listed_tambah_tahunan)"> -->
                  <v-tooltip
                    top
                    v-if="
                      (itemsTahunan.eselon == 2 &&
                        itemsTahunan.periode_input_skp) ||
                      itemsTahunan.periode_input_jabatan_baru ||
                      itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                      itemsTahunan.is_kode_skpd_listed_tambah_tahunan
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="green"
                        @click="openDialogFormatSKP()"
                        small
                        dark
                        class="mx-1"
                        >Tambah &nbsp;<v-icon small left
                          >mdi-plus</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Tambah SKP</span>
                  </v-tooltip>
                  <v-tooltip top v-if="walikota == false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        v-bind:color="
                          rowData.length > 0 ? 'success' : 'info darken-3'
                        "
                        @click="dialogLampiranSKP = true"
                        small
                        dark
                        class="mx-1"
                        >Lampiran &nbsp;<v-icon small left
                          >mdi-attachment</v-icon
                        ></v-btn
                      >
                    </template>
                    <span> Isi Lampiran SKP</span>
                  </v-tooltip>
                  <v-tooltip top v-if="walikota == false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                        @click="lihatFormEkspektasiPerilaku()"
                        small
                        dark
                        class="mx-1"
                        >Ekspektasi &nbsp;<v-icon small left
                          >mdi-account-check</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Lihat Ekspektasi Pimpinan</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="dialogTutupSKP = true"
                        color="red"
                        class="mx-1"
                        small
                        dark
                        >Tutup &nbsp;<v-icon small left>mdi-door</v-icon></v-btn
                      >
                    </template>
                    <span>Tutup SKP</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-btn
                    v-if="itemsTahunan.eselon == 2"
                    color="yellow darken-4"
                    @click="syncSakip"
                    :loading="loadingBtn.syncBtn"
                    class="mx-1"
                    small
                    dark
                    ><v-icon small left>mdi-sync</v-icon>
                    <v-img
                      max-width="75"
                      src="@/assets/logo_aplikasi_lain/ESAKIP.png"
                    ></v-img
                  ></v-btn>
                  <v-btn
                    v-if="
                      itemsTahunan.periode_input_skp ||
                      itemsTahunan.periode_input_jabatan_baru ||
                      itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                      enableAll
                    "
                    color="green"
                    @click="openDialogFormatSKP()"
                    small
                    dark
                    class="mx-1"
                    ><v-icon small left>mdi-plus</v-icon> Tambah SKP</v-btn
                  >
                  <v-btn
                    @click="dialogTutupSKP = true"
                    color="red"
                    class="mx-1"
                    small
                    dark
                    ><v-icon small left>mdi-door</v-icon> Tutup SKP</v-btn
                  >
                </div>
              </template>
              <!-- <v-btn v-if="itemsTahunan.periode_input_skp || itemsTahunan.periode_input_jabatan_baru || itemsTahunan.buka_tambah_skp_tahunan_by_nip || enableAll" color="green" @click="createItem()" small dark class="mx-1"><v-icon small left>mdi-plus</v-icon> Tambah SKP</v-btn> -->
              <!-- <v-btn v-if="itemsTahunan.periode_input_lampiran_skp || enableAll" color="green" @click="openDialogFormatSKP()" small dark class="mx-1"><v-icon small left>mdi-plus</v-icon> Tambah SKP</v-btn> -->
              <!-- <v-btn v-bind:color="rowData.length > 0 ? 'success' : 'info'" @click="dialogLampiranSKP = true" small dark class="mx-1"><v-icon small left>mdi-attachment</v-icon> Lampiran SKP</v-btn> -->
              <!-- <v-btn @click="getPengaturan()" :loading="loadingBtn.pengaturan" color="blue" small  dark class="mx-1"><v-icon small left>mdi-cog</v-icon> Pengaturan SKP</v-btn> -->
              <!-- <v-btn v-bind:color="rowData.length > 0 ? 'success' : 'info'" @click="dialogLampiranSKP = true" small dark class="mx-1"><v-icon small left>mdi-account-multiple</v-icon> Ekspektasi Perilaku Kerja</v-btn> -->
            </template>

            <template v-slot:append-actions="{ item }">
              <v-btn
                x-small
                color="primary"
                class="ma-1"
                @click="laporItem(item)"
                v-if="enableLapor"
              >
                <v-icon x-small left title="lapor skp"> mdi-file </v-icon>
                Lapor
              </v-btn>

              <v-btn
                x-small
                color="secondary"
                class="ma-1"
                @click="editItem(item)"
                v-if="
                  itemsTahunan.periode_input_skp ||
                  itemsTahunan.periode_input_jabatan_baru ||
                  itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                  enableAll ||
                  itemsTahunan.is_nip_listed_edit_tahunan ||
                  itemsTahunan.is_kode_skpd_listed_edit_tahunan ||
                  item.verifikasi == false
                "
              >
                <v-icon x-small left title="ubah data"> mdi-pencil </v-icon>
                Ubah
              </v-btn>

              <!-- <v-btn x-small :color="classAssignments" class="ma-1" @click="assignment(item)" v-if="item.status_bawahan && item.rencana_kinerja" >
            <v-icon x-small left title="Assignment Pembagian Matriks Peran-Hasil Rencana Kinerja">
              mdi-plus
            </v-icon>
            <strong>Assignment</strong>
          </v-btn> -->

              <confirm-btn
                v-if="
                  itemsTahunan.periode_input_skp ||
                  itemsTahunan.periode_input_jabatan_baru ||
                  enableAll ||
                  itemsTahunan.is_nip_listed_hapus_tahunan ||
                  itemsTahunan.is_kode_skpd_listed_hapus_tahunan
                "
                title="Hapus Sasaran Kinerja Tahunan"
                :content="
                  'Apakah Anda Yakin Akan Menghapus SKP Ini: <br> <strong>' +
                  (item.rencana_kinerja != null
                    ? item.rencana_kinerja
                    : item.sasaran_kinerja_tahunan) +
                  '</strong> '
                "
                :btnProps="{
                  class: 'ma-1',
                  name: 'hapus',
                  color: 'error',
                  xSmall: true,
                  icon: 'mdi-delete',
                }"
                @on-agree="deleteItem(item)"
              >
              </confirm-btn>

              <feedback-btn
                jenis="tahun"
                :item="item"
                @on-update-success="updateTableTahunan"
              ></feedback-btn>
            </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row v-if="walikota == false">
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2">Pengaturan SKP</h3>
          </template>
          <DataTablePengaturanSKP
            :crudSettings="crudSettings"
            :headers="headersPengaturanSKP"
            :responseData="itemsPengaturanSKP"
            :forceUpdateTable="forceUpdate"
            :dataLoading="loadingPengaturanSKP"
            @onTableChange="updateTablePengaturanSKP"
            @onEditClick="editItemPengaturanSKP"
          >
            <template v-slot:top>
              <p class="ma-3">
                Pengaturan SKP digunakan untuk mengatur data penilai SKP,
                periode SKP, dan jabatan yang sedang diamanahkan.
              </p>
            </template>

            <template v-slot:append-header>
              <v-spacer></v-spacer>
              <v-btn
                @click="getPengaturan(true)"
                :loading="loadingBtn.pengaturan"
                color="blue"
                small
                dark
                class="mx-1"
                ><v-icon small left>mdi-cog</v-icon> Tambah Pengaturan
                SKP</v-btn
              >
            </template>

            <template v-slot:append-actions="{ item }">
              <v-btn
                x-small
                color="secondary"
                class="ma-1"
                @click="editItemPengaturanSKP(item)"
              >
                <v-icon x-small left title="ubah data"> mdi-pencil </v-icon>
                Ubah Pengaturan SKP
              </v-btn>
              <!-- v-if="!item.is_sakip && item.validasi != 1" -->
              <confirm-btn
                title="Hapus Pengaturan SKP"
                :content="'Hapus \' Periode' + item.periode_skp + '\' ?'"
                :btnProps="{
                  class: 'ma-1',
                  name: 'hapus',
                  color: 'error',
                  xSmall: true,
                  icon: 'mdi-delete',
                }"
                @on-agree="deleteItemPengaturanSKP(item)"
              >
              </confirm-btn>
            </template>
          </DataTablePengaturanSKP>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row v-if="walikota == false">
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2">Sasaran Kinerja yang Sudah Dilaporkan</h3>
          </template>
          <crud
            :crudSettings="crudSettings"
            :headers="headersDilaporkan"
            :responseData="itemsDilaporkan"
            :forceUpdateTable="forceUpdate"
            :dataLoading="loadingDilaporkan"
            @onTableChange="updateTableDilaporkan"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="9">
                  <p class="ma-3">
                    Penutupan sasaran kinerja dilakukan ketika Anda akan pindah
                    atau berubah jabatan, atau akhir tahun.
                  </p>
                </v-col>
                <v-col cols="3" v-if="tahun < 2022">
                  <v-btn
                    class="success ma-3"
                    small
                    @click="exportPPKPNS()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Export PPKPNS Permenpan 8/2021"
                    ><v-icon small left>mdi-file-pdf-box</v-icon> PPKPNS
                    Permenpan 8/2021 &nbsp;</v-btn
                  >
                  <v-btn
                    class="success ma-3"
                    small
                    @click="exportPenilaianKinerja()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Export Penilaian Kinerja 2021"
                    ><v-icon small left>mdi-file-excel-box</v-icon> Penilaian
                    Kinerja 2021 &nbsp;</v-btn
                  >
                </v-col>
              </v-row>
            </template>
            <template v-slot:append-actions="{ item }">
              <v-chip
                x-small
                v-if="item.validasi == 1"
                small
                class="success darken-2"
                dark
                ><v-icon small left>mdi-check-circle</v-icon>validasi</v-chip
              >

              <v-btn
                v-if="item.validasi != 1"
                color="success"
                title="lapor ulang"
                x-small
                @click="laporItem(item)"
              >
                <v-icon left x-small> mdi-file </v-icon>
                Lapor Ulang
              </v-btn>

              <v-btn
                v-if="item.validasi != 1"
                x-small
                color="secondary"
                class="ma-1"
                @click="editItem(item)"
              >
                <v-icon x-small left title="ubah data"> mdi-pencil </v-icon>
                Ubah
              </v-btn>

              <confirm-btn
                v-if="item.validasi != 1"
                title="Hapus SKP Yang Sudah Dilaporkan"
                :content="
                  'Apakah Anda Yakin Akan Menghapus SKP Ini: <br> <strong>' +
                  (item.rencana_kinerja != null
                    ? item.rencana_kinerja
                    : item.sasaran_kinerja_tahunan) +
                  '</strong> <br><br> * Menghapus SKP Yang Sudah Dilaporkan Yaitu Mengembalikan SKP Ini Ke Kondisi Sebelum Dilaporkan '
                "
                :btnProps="{
                  class: 'ma-1',
                  name: 'hapus',
                  color: 'error',
                  xSmall: true,
                  icon: 'mdi-delete',
                }"
                @on-agree="deleteItem(item)"
              >
              </confirm-btn>

              <feedback-btn
                jenis="tahun"
                :item="item"
                @on-update-success="updateTableDilaporkan"
              ></feedback-btn>
            </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogFormatSKP" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Pilih Format SKP</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formFormatSKP">
            <v-row align="center">
              <v-col cols="4" align="center">
                <v-btn color="info" @click="createItem('lama')"
                  >PP 46/2011</v-btn
                >
              </v-col>
              <v-col cols="4" align="center">
                <v-btn color="success" @click="createItem('baru')"
                  >Permenpan 8/2021</v-btn
                >
              </v-col>
              <v-col cols="4" align="center" v-if="tahun >= 2022">
                <v-btn
                  color="cyan darken-4"
                  dark
                  @click="createItem('per6/2022')"
                  >Permenpan 6/2022</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTambahSKP" max-width="800px">
      <v-card>
        <v-card-text>
          <v-form ref="formTambah">
            <h1 class="display-2 my-2">
              Entri
              {{
                dialogFormatSKPBaru == true
                  ? "Rencana Kinerja"
                  : "Sasaran Kinerja"
              }}
              Pegawai
            </h1>

            <template>
              <div v-if="dialogFormatSKPBaru">
                <v-select
                  dense
                  v-model="formEdit.id_sasaran_strategis"
                  :items="listSasaranStrategis"
                  item-text="text"
                  item-value="id"
                  :rules="[rules.required]"
                  label="Sasaran Strategis"
                  outlined
                ></v-select>
                <!-- <v-select dense v-model="formEdit.id_tupoksi" :rules="[rules.required]" :items="listTupoksi" item-text="text" item-value="id" label="Tupoksi" outlined ></v-select> -->
                <v-select
                  dense
                  v-model="formEdit.id_tupoksi"
                  :items="listTupoksi"
                  item-text="text"
                  item-value="id"
                  label="Tupoksi"
                  outlined
                ></v-select>
                <v-select
                  dense
                  v-model="formEdit.kode_kegiatan"
                  :items="listKegiatan"
                  item-text="text"
                  item-value="id"
                  label="Kegiatan"
                  outlined
                ></v-select>
                <div v-if="itemsTahunan.eselon == 2">
                  <v-text-field
                    dense
                    v-model="formEdit.rencana_kinerja"
                    :rules="[rules.required]"
                    outlined
                    label="Rencana Kinerja"
                  ></v-text-field>
                </div>
                <div v-else>
                  <!-- <div v-if="itemsTahunan.periode_input_skp || itemsTahunan.periode_input_jabatan_baru || itemsTahunan.buka_tambah_skp_tahunan_by_nip || enableAll">
                  <v-select dense v-model="formEdit.id_skp_atasan" :rules="[rules.required]" :items="listSKPAtasan" item-text="text" item-value="id" label="Rencana Kinerja Atasan" outlined ></v-select>
                  <v-text-field dense v-model="formEdit.rencana_kinerja" :rules="[rules.required]" outlined label="Rencana Kinerja"></v-text-field>
                </div>
                <div v-else> -->
                  <v-select
                    :disabled="disabledSKPAtasan"
                    dense
                    v-model="formEdit.id_skp_atasan"
                    :rules="[rules.required]"
                    :items="listSKPAtasan"
                    item-text="text"
                    item-value="id"
                    label="Rencana Kinerja Atasan"
                    outlined
                  ></v-select>
                  <v-text-field
                    :disabled="disabledSKPAtasan"
                    dense
                    v-model="formEdit.rencana_kinerja"
                    :rules="[rules.required]"
                    outlined
                    label="Rencana Kinerja"
                  ></v-text-field>
                  <!-- </div> -->
                </div>

                <h1 class="display-2 my-2">Indikator Kinerja</h1>
                <v-checkbox
                  v-model="formEdit.is_skp_iki"
                  label="SKP IKI"
                ></v-checkbox>
                <!-- <v-select dense v-model="formEdit.aspek" :items="listAspek2" item-text="text" item-value="value" label="Aspek" outlined ></v-select> -->
                <!-- <v-textarea v-model="formEdit.indikator" label="Indikator Kegiatan/Ouput/Keluaran" outlined auto-grow :disabled="!formEdit.is_skp_iki"></v-textarea> -->

                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      v-model="formEdit.indikator_kuantitas"
                      :disabled="!formEdit.is_skp_iki"
                      outlined
                      label="Indikator Kuantitas (Target)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      v-model="formEdit.target_bawah"
                      :disabled="!formEdit.indikator_kuantitas"
                      :rules="
                        formEdit.indikator_kuantitas != null
                          ? [rules.required, rules.onlyInt]
                          : []
                      "
                      outlined
                      label="Target Batas Bawah"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      v-model="formEdit.target_atas"
                      :disabled="!formEdit.target_bawah"
                      outlined
                      label="Target Batas Atas"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      dense
                      v-model="formEdit.satuan_target"
                      :disabled="!formEdit.target_bawah"
                      :rules="
                        formEdit.indikator_kuantitas != null
                          ? [rules.required]
                          : []
                      "
                      outlined
                      :items="satuanSKP"
                      label="Satuan"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      v-model="formEdit.indikator_kualitas"
                      :disabled="!formEdit.is_skp_iki"
                      outlined
                      label="Indikator Kualitas"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      v-model="formEdit.kualitas_batas_bawah"
                      :disabled="!formEdit.indikator_kualitas"
                      :rules="
                        formEdit.indikator_kualitas != null
                          ? [rules.required, rules.onlyInt]
                          : []
                      "
                      outlined
                      label="Kualitas Batas Bawah"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      v-model="formEdit.kualitas_batas_atas"
                      :disabled="!formEdit.kualitas_batas_bawah"
                      outlined
                      label="Kualitas Batas Atas"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col>
                  <v-text-field dense v-model="formEdit.kualitas" :rules="[rules.required, rules.onlyInt]" outlined label="Kualitas" append-icon="mdi-percent"></v-text-field>
                </v-col> -->
                </v-row>

                <v-row align="center">
                  <v-col>
                    <v-text-field
                      dense
                      v-model="formEdit.indikator_waktu"
                      :disabled="!formEdit.is_skp_iki"
                      outlined
                      label="Indikator Waktu"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      v-model="formEdit.waktu_batas_bawah"
                      :disabled="!formEdit.indikator_waktu"
                      :rules="
                        formEdit.indikator_waktu != null
                          ? [rules.required, rules.onlyInt]
                          : []
                      "
                      outlined
                      label="Waktu Batas Bawah"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      v-model="formEdit.waktu_batas_atas"
                      :disabled="!formEdit.waktu_batas_bawah"
                      outlined
                      label="Waktu Batas Atas"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="3">
                  <v-select dense v-model="formEdit.waktu" label="Waktu Pengerjaan" outlined :items='nilaiBulan' :rules="[rules.required]"></v-select>
                </v-col> -->
                  <v-col cols="2">
                    <v-select
                      dense
                      v-model="formEdit.satuan_waktu"
                      label="Satuan"
                      outlined
                      :disabled="!formEdit.is_skp_iki"
                      :items="['bulan']"
                      :rules="
                        formEdit.indikator_waktu != null ? [rules.required] : []
                      "
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row align="center">
                  <span class="mx-3">Rumus Penurunan</span>
                  <v-checkbox
                    v-model="formEdit.is_pembalik"
                    label="Nilai Pembalik"
                  ></v-checkbox>
                </v-row>

                <v-row align="center">
                  <span class="mx-3">Direct Cascading</span>
                  <v-checkbox
                    class="mx-3"
                    style="margin-top: 0px !important"
                    v-model="formEdit.direct_cascading"
                    label="Centang pilihan direct cascading untuk rencana kinerja yang dapat dibagi berdasarkan aspek, wilayah, dan/atau beban target kuantitatif "
                  ></v-checkbox>
                </v-row>

                <h1 class="display-2 my-2">Indikator Keuangan</h1>
                <v-text-field
                  dense
                  :value="formEdit.kegiatan"
                  outlined
                  label="Kegiatan"
                  disabled
                ></v-text-field>
                <v-text-field
                  dense
                  :value="formEdit.pagu"
                  outlined
                  label="Pagu"
                  disabled
                ></v-text-field>
              </div>

              <div v-else>
                <v-select
                  dense
                  v-model="formEdit.id_sasaran_strategis"
                  :items="listSasaranStrategis"
                  item-text="text"
                  item-value="id"
                  :rules="[rules.required]"
                  label="Sasaran Strategis"
                  outlined
                ></v-select>

                <!-- <v-select dense v-model="formEdit.id_tupoksi" :rules="[rules.required]" :items="listTupoksi" item-text="text" item-value="id" label="Tupoksi" outlined ></v-select> -->
                <v-select
                  dense
                  v-model="formEdit.id_tupoksi"
                  :items="listTupoksi"
                  item-text="text"
                  item-value="id"
                  label="Tupoksi"
                  outlined
                ></v-select>

                <v-select
                  dense
                  v-model="formEdit.kode_kegiatan"
                  :items="listKegiatan"
                  item-text="text"
                  item-value="id"
                  label="Kegiatan"
                  outlined
                ></v-select>

                <v-select
                  dense
                  v-model="formEdit.id_skp_atasan"
                  :rules="[rules.required]"
                  :items="listSKPAtasan"
                  item-text="text"
                  item-value="id"
                  label="SKP atasan langsung"
                  outlined
                ></v-select>

                <v-text-field
                  dense
                  v-model="formEdit.sasaran_kinerja_tahunan"
                  :rules="[rules.required]"
                  outlined
                  label="SKP Tahunan"
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      v-model="formEdit.target"
                      :rules="[rules.required, rules.onlyInt]"
                      outlined
                      label="Target"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      dense
                      v-model="formEdit.satuan_target"
                      :rules="[rules.required]"
                      outlined
                      :items="satuanSKP"
                      label="Satuan"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      v-model="formEdit.kualitas"
                      :rules="[rules.required, rules.onlyInt]"
                      outlined
                      label="Kualitas"
                      append-icon="mdi-percent"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="4">
                    <v-select
                      dense
                      v-model="formEdit.waktu"
                      label="waktu"
                      outlined
                      :items="nilaiBulan"
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      dense
                      v-model="formEdit.satuan_waktu"
                      label="satuan"
                      outlined
                      :items="['bulan']"
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <span class="mx-3" style="font-size: 12px"
                    >Rumus Penurunan</span
                  >
                  <v-checkbox
                    v-model="formEdit.is_pembalik"
                    label="Nilai Pembalik"
                  ></v-checkbox>
                </v-row>

                <h1 class="display-2 my-2">Indikator Kinerja</h1>
                <v-checkbox
                  v-model="formEdit.is_skp_iki"
                  label="SKP IKI"
                ></v-checkbox>
                <v-textarea
                  v-model="formEdit.indikator"
                  label="Indikator Kegiatan/Ouput/Keluaran"
                  outlined
                  auto-grow
                  :disabled="!formEdit.is_skp_iki"
                ></v-textarea>

                <h1 class="display-2 my-2">Indikator Keuangan</h1>
                <v-text-field
                  dense
                  :value="formEdit.kegiatan"
                  outlined
                  label="Kegiatan"
                  disabled
                ></v-text-field>
                <v-text-field
                  dense
                  :value="formEdit.pagu"
                  outlined
                  label="Pagu"
                  disabled
                ></v-text-field>
              </div>
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogTambahSKP = false">Tutup</v-btn>
          <v-btn
            color="success"
            :loading="loadingBtn.tambahSKP"
            @click="simpanItem()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTambahSKPPermenpan6" max-width="800px">
      <v-card>
        <v-card-text>
          <v-form ref="formTambahSKPPermenpan6">
            <h1 class="display-2 my-2">Entri Rencana Kinerja Pegawai</h1>

            <template>
              <v-select
                dense
                v-model="formEdit.id_sasaran_strategis"
                :items="listSasaranStrategis"
                item-text="text"
                item-value="id"
                :rules="[rules.required]"
                label="Sasaran Strategis"
                outlined
              ></v-select>
              <!-- <v-select dense v-model="formEdit.id_tupoksi" :rules="[rules.required]" :items="listTupoksi" item-text="text" item-value="id" label="Tupoksi" outlined ></v-select> -->
              <!-- <v-select dense v-model="formEdit.id_tupoksi" :items="listTupoksi" item-text="text" item-value="id" label="Tupoksi" outlined ></v-select> -->
              <v-select
                dense
                v-model="formEdit.kode_kegiatan"
                :items="listKegiatan"
                item-text="text"
                item-value="id"
                label="Kegiatan"
                outlined
              ></v-select>
              <div v-if="itemsTahunan.eselon == 2">
                <v-text-field
                  dense
                  v-model="formEdit.rencana_kinerja"
                  :rules="[rules.required]"
                  outlined
                  label="Rencana Kinerja"
                ></v-text-field>
              </div>
              <div v-else>
                <!-- <div v-if="itemsTahunan.periode_input_skp || itemsTahunan.periode_input_jabatan_baru || itemsTahunan.buka_tambah_skp_tahunan_by_nip || enableAll">
                  <v-select dense v-model="formEdit.id_skp_atasan" :rules="[rules.required]" :items="listSKPAtasan" item-text="text" item-value="id" label="Rencana Kinerja Atasan" outlined ></v-select>
                  <v-text-field dense v-model="formEdit.rencana_kinerja" :rules="[rules.required]" outlined label="Rencana Kinerja"></v-text-field>
                </div>
                <div v-else> -->
                <div v-if="tahun >= 2022">
                  <!-- <div v-if="tempSKPAtasan.group != undefined">
                      <h4>Rencana Kinerja Atasan:</h4>
                      <p>{{tempSKPAtasan.rencana_kinerja_tahunan}} <strong>{{tempSKPAtasan.group}}</strong></p>
                    </div> -->
                  <v-autocomplete
                    v-model="formEdit.id_skp_atasan"
                    :items="dataSKPAtasan"
                    label="Rencana Kinerja Atasan"
                    item-text="rencana_kinerja_tahunan"
                    item-value="id"
                    dense
                    outlined
                    @change="changeSKPAtasan"
                    :rules="
                      input_skp_dibebaskan_by_nip === true
                        ? []
                        : [rules.required]
                    "
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.rencana_kinerja_tahunan"
                        ></v-list-item-title>
                        <strong
                          ><v-list-item-sub-title
                            v-html="data.item.group"
                          ></v-list-item-sub-title
                        ></strong>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    dense
                    v-model="formEdit.rencana_kinerja"
                    :rules="[rules.required]"
                    outlined
                    label="Rencana Kinerja"
                  ></v-text-field>
                </div>
                <div v-else>
                  <v-select
                    dense
                    v-model="formEdit.id_skp_atasan"
                    :rules="[rules.required]"
                    :items="listSKPAtasan"
                    item-text="text"
                    item-value="id"
                    label="Rencana Kinerja Atasan"
                    outlined
                  ></v-select>
                </div>
                <!-- </div> -->
              </div>

              <!-- <div v-if="tahun >= 2022">
                <v-textarea v-model="formEdit.kriteria"  outlined label="Kriteria"></v-textarea>
              </div> -->

              <!-- Penambahan perspektif Permenpan 6 untuk JPT -->
              <v-select
                v-if="itemsTahunan.eselon == 2 || isPLT"
                dense
                v-model="formEdit.perspektif"
                :items="listPerspektif"
                :rules="[rules.required]"
                item-text="text"
                item-value="value"
                label="Perspektif"
                outlined
              ></v-select>
              <!-- <v-select v-else disabled dense v-model="formEdit.perspektif" :items="listPerspektif" item-text="text" item-value="value" label="Perspektif" outlined ></v-select> -->

              <h1 class="display-2 my-2">Indikator Kinerja</h1>
              <v-row>
                <v-col>
                  <v-checkbox v-model="formEdit.is_skp_iki" @change="skpIki">
                    <template slot="label"><div>SKP IKI</div></template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-select
                dense
                v-model="formEdit.aspek"
                :items="listAspek2"
                item-text="text"
                item-value="value"
                label="Aspek"
                :rules="[rules.required]"
                outlined
              ></v-select>
              <!-- <v-textarea v-model="formEdit.indikator" label="Indikator Kegiatan/Ouput/Keluaran" outlined auto-grow :disabled="!formEdit.is_skp_iki"></v-textarea> -->

              <v-row class="mt-4">
                <v-col>
                  <!-- <v-text-field dense v-model="formEdit.indikator_kuantitas" :disabled="!formEdit.is_skp_iki" outlined label="Indikator Kuantitas"></v-text-field> -->
                  <v-text-field
                    dense
                    v-model="formEdit.indikator_kuantitas"
                    outlined
                    label="Indikator"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="formEdit.target_bawah"
                    :rules="[rules.required]"
                    outlined
                    label="Target"
                  ></v-text-field>
                  <!-- <v-text-field dense v-model="formEdit.target_bawah" :disabled="!disabledForm" :rules="formEdit.indikator_kuantitas != null ? [rules.required, rules.onlyInt] : []" outlined label="Target"></v-text-field> -->
                </v-col>
                <!-- <v-col cols="3">
                  <v-text-field dense v-model="formEdit.target_atas" :disabled="!formEdit.target_bawah" outlined label="Target Batas Atas"></v-text-field>
                </v-col> -->
                <v-col cols="2">
                  <v-select
                    dense
                    v-model="formEdit.satuan_target"
                    :rules="
                      formEdit.indikator_kuantitas != null
                        ? [rules.required]
                        : []
                    "
                    outlined
                    :items="satuanSKP"
                    label="Satuan"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col cols="4">
                  <v-text-field dense v-model="formEdit.indikator_kualitas" :disabled="!formEdit.is_skp_iki" outlined label="Indikator Kualitas"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field dense v-model="formEdit.kualitas_batas_bawah" :disabled="!formEdit.indikator_kualitas" :rules="formEdit.indikator_kualitas != null ? [rules.required, rules.onlyInt] : []" outlined label="Kualitas Batas Bawah"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field dense v-model="formEdit.kualitas_batas_atas" :disabled="!formEdit.kualitas_batas_bawah" outlined label="Kualitas Batas Atas"></v-text-field>
                </v-col>
              </v-row> -->

              <!-- <v-row align="center">
                <v-col>
                  <v-text-field dense v-model="formEdit.indikator_waktu" :disabled="!formEdit.is_skp_iki" outlined label="Indikator Waktu"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field dense v-model="formEdit.waktu_batas_bawah" :disabled="!formEdit.indikator_waktu" :rules="formEdit.indikator_waktu != null ? [rules.required, rules.onlyInt] : []" outlined label="Waktu Batas Bawah"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field dense v-model="formEdit.waktu_batas_atas" :disabled="!formEdit.waktu_batas_bawah" outlined label="Waktu Batas Atas"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select dense v-model="formEdit.satuan_waktu" label="Satuan" outlined :disabled="!formEdit.is_skp_iki" :items='["bulan"]' :rules="formEdit.indikator_waktu != null ? [rules.required] : []"></v-select>
                </v-col>
              </v-row> -->

              <v-row align="center">
                <span class="mx-3">Rumus Penurunan</span>
                <v-checkbox
                  v-model="formEdit.is_pembalik"
                  label="Nilai Pembalik"
                ></v-checkbox>
              </v-row>

              <v-row align="center">
                <span class="mx-3">Direct Cascading</span>
                <v-checkbox
                  class="mx-3"
                  style="margin-top: 0px !important"
                  v-model="formEdit.direct_cascading"
                  label="Centang pilihan direct cascading untuk rencana kinerja yang dapat dibagi berdasarkan aspek, wilayah, dan/atau beban target kuantitatif "
                ></v-checkbox>
              </v-row>

              <!-- <h1 class="display-2 my-2">Indikator Keuangan</h1>
              <v-text-field dense :value="formEdit.kegiatan" outlined  label="Kegiatan" disabled></v-text-field>
              <v-text-field dense :value="formEdit.pagu" outlined label="Pagu" disabled></v-text-field> -->
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogTambahSKPPermenpan6 = false">Tutup</v-btn>
          <v-btn
            color="success"
            :loading="loadingBtn.tambahSKP"
            @click="simpanItem()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditSKPPermenpan6" max-width="800px">
      <v-card>
        <v-card-text>
          <v-form ref="formEditSKPPermenpan6">
            <h1 class="display-2 my-2">Edit Rencana Kinerja Pegawai Tahunan</h1>

            <template v-if="tahun == 2022">
              <v-select
                dense
                v-model="formEdit.id_sasaran_strategis"
                :items="listSasaranStrategis"
                item-text="text"
                item-value="id"
                :rules="[rules.required]"
                label="Sasaran Strategis"
                outlined
              ></v-select>
              <!-- <v-select dense v-model="formEdit.id_tupoksi" :rules="[rules.required]" :items="listTupoksi" item-text="text" item-value="id" label="Tupoksi" outlined ></v-select> -->
              <v-select
                dense
                v-model="formEdit.id_tupoksi"
                :items="listTupoksi"
                item-text="text"
                item-value="id"
                label="Tupoksi"
                outlined
              ></v-select>
              <v-select
                dense
                v-model="formEdit.kode_kegiatan"
                :items="listKegiatan"
                item-text="text"
                item-value="id"
                label="Kegiatan"
                outlined
              ></v-select>
              <div v-if="itemsTahunan.eselon == 2">
                <v-text-field
                  dense
                  v-model="formEdit.rencana_kinerja"
                  :rules="[rules.required]"
                  outlined
                  label="Rencana Kinerja"
                ></v-text-field>
              </div>
              <div v-else>
                <!-- <div v-if="itemsTahunan.periode_input_skp || itemsTahunan.periode_input_jabatan_baru || itemsTahunan.buka_tambah_skp_tahunan_by_nip || enableAll">
                  <v-select dense v-model="formEdit.id_skp_atasan" :rules="[rules.required]" :items="listSKPAtasan" item-text="text" item-value="id" label="Rencana Kinerja Atasan" outlined ></v-select>
                  <v-text-field dense v-model="formEdit.rencana_kinerja" :rules="[rules.required]" outlined label="Rencana Kinerja"></v-text-field>
                </div>
                <div v-else> -->
                <div v-if="tahun >= 2022">
                  <div v-if="tempSKPAtasan.group != undefined">
                    <h4>Rencana Kinerja Atasan:</h4>
                    <p>
                      {{ tempSKPAtasan.rencana_kinerja_tahunan }}
                      <strong>{{ tempSKPAtasan.group }}</strong>
                    </p>
                  </div>
                  <v-autocomplete
                    v-model="formEdit.id_skp_atasan"
                    :items="dataSKPAtasan"
                    label="Rencana Kinerja Atasan"
                    item-text="rencana_kinerja_tahunan"
                    item-value="id"
                    dense
                    outlined
                    @change="changeSKPAtasan"
                    :rules="[rules.required]"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.rencana_kinerja_tahunan"
                        ></v-list-item-title>
                        <strong
                          ><v-list-item-sub-title
                            v-html="data.item.group"
                          ></v-list-item-sub-title
                        ></strong>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    dense
                    v-model="formEdit.rencana_kinerja"
                    :rules="[rules.required]"
                    outlined
                    label="Rencana Kinerja"
                  ></v-text-field>
                </div>
                <div v-else>
                  <v-select
                    dense
                    v-model="formEdit.id_skp_atasan"
                    :rules="[rules.required]"
                    :items="listSKPAtasan"
                    item-text="text"
                    item-value="id"
                    label="Rencana Kinerja Atasan"
                    outlined
                  ></v-select>
                </div>
                <!-- </div> -->
              </div>

              <!-- Penambahan perspektif Permenpan 6 untuk JPT -->
              <v-select
                v-if="itemsTahunan.eselon == 2 || isPLT"
                dense
                v-model="formEdit.perspektif"
                :items="listPerspektif"
                :rules="[rules.required]"
                item-text="text"
                item-value="value"
                label="Perspektif"
                outlined
              ></v-select>
              <v-select
                v-else
                disabled
                dense
                v-model="formEdit.perspektif"
                :items="listPerspektif"
                item-text="text"
                item-value="value"
                label="Perspektif"
                outlined
              ></v-select>

              <h1 class="display-2 my-2">Indikator Kinerja</h1>
              <v-checkbox
                v-model="formEdit.is_skp_iki"
                label="SKP IKI"
              ></v-checkbox>
              <!-- <v-select dense v-model="formEdit.aspek" :items="listAspek2" item-text="text" item-value="value" label="Aspek" outlined ></v-select> -->
              <!-- <v-textarea v-model="formEdit.indikator" label="Indikator Kegiatan/Ouput/Keluaran" outlined auto-grow :disabled="!formEdit.is_skp_iki"></v-textarea> -->

              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="formEdit.indikator_kuantitas"
                    :disabled="!formEdit.is_skp_iki"
                    outlined
                    label="Indikator Kuantitas (Target)"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="formEdit.target_bawah"
                    :disabled="!formEdit.indikator_kuantitas"
                    :rules="
                      formEdit.indikator_kuantitas != null
                        ? [rules.required, rules.onlyInt]
                        : []
                    "
                    outlined
                    label="Target Batas Bawah"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="formEdit.target_atas"
                    :disabled="!formEdit.target_bawah"
                    outlined
                    label="Target Batas Atas"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                    dense
                    v-model="formEdit.satuan_target"
                    :disabled="!formEdit.target_bawah"
                    :rules="
                      formEdit.indikator_kuantitas != null
                        ? [rules.required]
                        : []
                    "
                    outlined
                    :items="satuanSKP"
                    label="Satuan"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="formEdit.indikator_kualitas"
                    :disabled="!formEdit.is_skp_iki"
                    outlined
                    label="Indikator Kualitas"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="formEdit.kualitas_batas_bawah"
                    :disabled="!formEdit.indikator_kualitas"
                    :rules="
                      formEdit.indikator_kualitas != null
                        ? [rules.required, rules.onlyInt]
                        : []
                    "
                    outlined
                    label="Kualitas Batas Bawah"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="formEdit.kualitas_batas_atas"
                    :disabled="!formEdit.kualitas_batas_bawah"
                    outlined
                    label="Kualitas Batas Atas"
                  ></v-text-field>
                </v-col>
                <!-- <v-col>
                  <v-text-field dense v-model="formEdit.kualitas" :rules="[rules.required, rules.onlyInt]" outlined label="Kualitas" append-icon="mdi-percent"></v-text-field>
                </v-col> -->
              </v-row>

              <v-row align="center">
                <v-col>
                  <v-text-field
                    dense
                    v-model="formEdit.indikator_waktu"
                    :disabled="!formEdit.is_skp_iki"
                    outlined
                    label="Indikator Waktu"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="formEdit.waktu_batas_bawah"
                    :disabled="!formEdit.indikator_waktu"
                    :rules="
                      formEdit.indikator_waktu != null
                        ? [rules.required, rules.onlyInt]
                        : []
                    "
                    outlined
                    label="Waktu Batas Bawah"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    dense
                    v-model="formEdit.waktu_batas_atas"
                    :disabled="!formEdit.waktu_batas_bawah"
                    outlined
                    label="Waktu Batas Atas"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="3">
                  <v-select dense v-model="formEdit.waktu" label="Waktu Pengerjaan" outlined :items='nilaiBulan' :rules="[rules.required]"></v-select>
                </v-col> -->
                <v-col cols="2">
                  <v-select
                    dense
                    v-model="formEdit.satuan_waktu"
                    label="Satuan"
                    outlined
                    :disabled="!formEdit.is_skp_iki"
                    :items="['bulan']"
                    :rules="
                      formEdit.indikator_waktu != null ? [rules.required] : []
                    "
                  ></v-select>
                </v-col>
              </v-row>

              <v-row align="center">
                <span class="mx-3">Rumus Penurunan</span>
                <v-checkbox
                  v-model="formEdit.is_pembalik"
                  label="Nilai Pembalik"
                ></v-checkbox>
              </v-row>

              <v-row align="center">
                <span class="mx-3">Direct Cascading</span>
                <v-checkbox
                  class="mx-3"
                  style="margin-top: 0px !important"
                  v-model="formEdit.direct_cascading"
                  label="Centang pilihan direct cascading untuk rencana kinerja yang dapat dibagi berdasarkan aspek, wilayah, dan/atau beban target kuantitatif "
                ></v-checkbox>
              </v-row>

              <!-- <h1 class="display-2 my-2">Indikator Keuangan</h1>
              <v-text-field dense :value="formEdit.kegiatan" outlined  label="Kegiatan" disabled></v-text-field>
              <v-text-field dense :value="formEdit.pagu" outlined label="Pagu" disabled></v-text-field> -->
            </template>

            <template v-if="tahun >= 2023">
              <div>
                <!-- <v-row>
                  <v-col> -->
                <div v-if="tahun < 2024">
                  <v-checkbox
                    v-if="itemsTahunan.eselon == 0"
                    v-model="formEdit.is_skp_iki"
                  >
                    <template slot="label">
                      <div>SKP IKI</div>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    v-else
                    v-model="formEdit.is_skp_iki"
                    @change="skpIki"
                    :disabled="
                      !(
                        itemsTahunan.eselon == 2 ||
                        itemsTahunan.eselon == 3 ||
                        itemsTahunan.eselon == 4
                      )
                    "
                  >
                    <template slot="label">
                      <div
                        v-if="
                          itemsTahunan.eselon == 2 ||
                          itemsTahunan.eselon == 3 ||
                          itemsTahunan.eselon == 4
                        "
                      >
                        SKP IKI / Sinkronisasi E-Sakip {{ this.tahun }}
                        <br />(Silahkan <b>hapus</b> centang jika ingin
                        mengambil indikator dan target dari sistem E-Sakip)
                      </div>
                      <div v-else>SKP IKI</div>
                    </template>
                  </v-checkbox>
                </div>
                <div v-if="tahun >= 2024">
                  <v-checkbox
                    v-if="itemsTahunan.eselon == 0"
                    v-model="formEdit.is_skp_iki"
                  >
                    <template slot="label">
                      <div>SKP IKI</div>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    v-else
                    v-model="formEdit.is_sakip"
                    @change="skpSyncEsakip"
                    :disabled="
                      !(
                        itemsTahunan.eselon == 2 ||
                        itemsTahunan.eselon == 3 ||
                        itemsTahunan.eselon == 4
                      )
                    "
                  >
                    <template slot="label">
                      <div
                        v-if="
                          itemsTahunan.eselon == 2 ||
                          itemsTahunan.eselon == 3 ||
                          itemsTahunan.eselon == 4
                        "
                      >
                        <b>Sinkronisasi E-Sakip {{ this.tahun }}</b>
                        <br />
                        <b
                          >(Silahkan centang jika ingin mengambil indikator dan
                          target dari sistem E-Sakip)</b
                        >
                      </div>
                      <div v-else>SKP IKI</div>
                    </template>
                  </v-checkbox>
                </div>
                <!-- </v-col>
                </v-row> -->
                <!-- <v-row> -->
                <!-- <v-col align="left" justify="center" v-if="formEdit.is_skp_iki == false"> -->
                <div
                  align="left"
                  justify="center"
                  v-if="tahun < 2024 && formEdit.is_skp_iki == false"
                >
                  <div v-if="itemsTahunan.eselon == 3">
                    <span>Sinkronisasi&nbsp;:&nbsp;</span>
                    <v-btn
                      color="cyan darken-4"
                      @click="syncSakipProgram"
                      :loading="loadingBtn.syncSakipProgram"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Program &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                    <v-btn
                      color="green darken-4"
                      @click="syncSakipKegiatan"
                      :loading="loadingBtn.syncSakipKegiatan"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Kegiatan &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                  </div>
                  <div v-if="itemsTahunan.eselon == 4">
                    <span>Sinkronisasi&nbsp;:&nbsp;</span>
                    <v-btn
                      color="cyan darken-4"
                      @click="syncSakipProgram"
                      :loading="loadingBtn.syncSakipProgram"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Program &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                    <v-btn
                      color="teal darken-4"
                      @click="syncSakipKegiatan"
                      :loading="loadingBtn.syncSakipKegiatan"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Kegiatan &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                    <v-btn
                      color="green darken-4"
                      @click="syncSakipSubKegiatan"
                      :loading="loadingBtn.syncSakipSubKegiatan"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp;Sub Kegiatan &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                  </div>
                </div>
                <div
                  align="left"
                  justify="center"
                  v-if="tahun >= 2024 && formEdit.is_sakip"
                >
                  <div v-if="itemsTahunan.eselon == 3">
                    <span>Sinkronisasi&nbsp;:&nbsp;</span>
                    <v-btn
                      color="cyan darken-4"
                      @click="syncSakipProgram"
                      :loading="loadingBtn.syncSakipProgram"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Program &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                    <v-btn
                      color="green darken-4"
                      @click="syncSakipKegiatan"
                      :loading="loadingBtn.syncSakipKegiatan"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Kegiatan &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                  </div>
                  <div v-if="itemsTahunan.eselon == 4">
                    <span>Sinkronisasi&nbsp;:&nbsp;</span>
                    <v-btn
                      color="cyan darken-4"
                      @click="syncSakipProgram"
                      :loading="loadingBtn.syncSakipProgram"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Program &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                    <v-btn
                      color="teal darken-4"
                      @click="syncSakipKegiatan"
                      :loading="loadingBtn.syncSakipKegiatan"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp; Kegiatan &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                    <v-btn
                      color="green darken-4"
                      @click="syncSakipSubKegiatan"
                      :loading="loadingBtn.syncSakipSubKegiatan"
                      class="mx-1"
                      small
                      dark
                      >&nbsp; &nbsp;Sub Kegiatan &nbsp;<v-icon small left
                        >mdi-sync</v-icon
                      ></v-btn
                    >
                  </div>
                </div>
                <!-- </v-col> -->
                <!-- </v-row> -->
              </div>

              <!-- <div v-if="tahun >= 2023">
                <v-row>
                  <v-col>
                    <v-select dense v-model="formEdit.id_sasaran_strategis" :items="listSasaranStrategis" item-text="text" item-value="id" :rules="[rules.required]" label="Sasaran Strategis" outlined ></v-select>
                  </v-col>
                </v-row>
              </div> -->
              <div class="mt-5">
                <v-row>
                  <v-col>
                    <div v-if="tahun < 2024">
                      <v-select
                        ref="syncProgramListSasaranStrategis"
                        dense
                        v-model="formEdit.id_sasaran_strategis"
                        :items="listSasaranStrategis"
                        item-text="text"
                        item-value="id"
                        :rules="[rules.required]"
                        label="Sasaran Strategis"
                        outlined
                      ></v-select>
                    </div>
                    <div v-if="tahun >= 2024">
                      <v-select
                        ref="syncProgramListSasaranStrategis"
                        dense
                        v-model="formEdit.id_sasaran_strategis"
                        :items="listSasaranStrategis"
                        item-text="text"
                        item-value="id"
                        :rules="[rules.required]"
                        label="Sasaran Strategis"
                        outlined
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
                <div v-if="itemsTahunan.eselon == 2">
                  <v-text-field
                    dense
                    v-model="formEdit.rencana_kinerja"
                    :rules="[rules.required]"
                    outlined
                    label="Rencana Kinerja"
                  ></v-text-field>
                </div>
                <div v-else>
                  <div v-if="tahun < 2024">
                    <!-- <div v-if="tempSKPAtasan.group != undefined">
                        <h4>Rencana Kinerja Atasan:</h4>
                        <p>{{tempSKPAtasan.rencana_kinerja_tahunan}} <strong>{{tempSKPAtasan.group}}</strong></p>
                      </div> -->
                    <v-autocomplete
                      v-model="formEdit.id_skp_atasan"
                      :items="dataSKPAtasan"
                      label="Rencana Kinerja Atasan"
                      item-text="rencana_kinerja_tahunan"
                      item-value="id"
                      dense
                      outlined
                      :disabled="
                        (itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                          itemsTahunan.is_kode_skpd_listed_tambah_tahunan) ==
                        true
                          ? false
                          : true
                      "
                      @change="changeSKPAtasan"
                      :rules="
                        input_skp_dibebaskan_by_nip === true ||
                        (itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                          itemsTahunan.is_kode_skpd_listed_tambah_tahunan) ==
                          false
                          ? []
                          : [rules.required]
                      "
                    >
                      <template slot="item" slot-scope="data">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.rencana_kinerja_tahunan"
                          ></v-list-item-title>
                          <strong
                            ><v-list-item-sub-title
                              v-html="data.item.group"
                            ></v-list-item-sub-title
                          ></strong>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>

                    <v-text-field
                      dense
                      v-model="formEdit.rencana_kinerja"
                      :rules="[rules.required]"
                      :disabled="true"
                      outlined
                      label="Rencana Kinerja"
                    ></v-text-field>
                  </div>
                  <div v-if="tahun >= 2024">
                    <v-autocomplete
                      v-model="formEdit.id_skp_atasan"
                      :items="dataSKPAtasan"
                      label="Rencana Kinerja Atasan"
                      item-text="rencana_kinerja_tahunan"
                      item-value="id"
                      dense
                      outlined
                      :disabled="
                        formEdit.is_sakip ||
                        (itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                          itemsTahunan.is_kode_skpd_listed_tambah_tahunan) ==
                          false
                          ? true
                          : false
                      "
                      @change="changeSKPAtasan"
                      :rules="
                        input_skp_dibebaskan_by_nip === true ||
                        (itemsTahunan.buka_tambah_skp_tahunan_by_nip ||
                          itemsTahunan.is_kode_skpd_listed_tambah_tahunan) ==
                          false
                          ? []
                          : [rules.required]
                      "
                    >
                      <template slot="item" slot-scope="data">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.rencana_kinerja_tahunan"
                          ></v-list-item-title>
                          <strong
                            ><v-list-item-sub-title
                              v-html="data.item.group"
                            ></v-list-item-sub-title
                          ></strong>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      dense
                      v-model="formEdit.rencana_kinerja"
                      :rules="[rules.required]"
                      :disabled="true"
                      outlined
                      label="Rencana Kinerja"
                    ></v-text-field>
                  </div>
                  <!-- <div v-else>
                      <v-select dense v-model="formEdit.id_skp_atasan" :rules="[rules.required]" :items="listSKPAtasan" item-text="text" item-value="id" label="Rencana Kinerja Atasan" outlined ></v-select>
                    </div> -->
                </div>
                <v-row>
                  <v-col>
                    <v-select
                      dense
                      v-model="formEdit.aspek"
                      :items="listAspek2"
                      item-text="text"
                      item-value="value"
                      label="Aspek"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div v-if="tahun < 2024">
                      <div v-if="itemsTahunan.eselon != 0">
                        <v-select
                          v-if="formEdit.is_skp_iki == false"
                          ref="syncProgramIndikator"
                          dense
                          v-model="formEdit.indikator_kuantitas"
                          :items="listIndikator"
                          @change="changeTarget"
                          item-text="text"
                          item-value="id"
                          label="Indikator"
                          outlined
                        >
                          <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            <div
                              align="left"
                              justify="center"
                              class="mt-2 mb-0"
                            >
                              {{ data.item.text }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <!-- <v-text-field v-if="formEdit.is_skp_iki" dense v-model="formEdit.indikator_kuantitas" :disabled="!formEdit.is_skp_iki" outlined label="Indikator Kuantitas"></v-text-field> -->
                      <div v-if="itemsTahunan.eselon != 0">
                        <v-text-field
                          v-if="formEdit.is_skp_iki"
                          dense
                          v-model="formEdit.indikator_kuantitas"
                          :rules="[rules.required]"
                          outlined
                          label="Indikator"
                        ></v-text-field>
                      </div>
                      <div v-else>
                        <v-text-field
                          dense
                          v-model="formEdit.indikator_kuantitas"
                          :rules="[rules.required]"
                          outlined
                          label="Indikator"
                        ></v-text-field>
                      </div>
                    </div>
                    <div v-if="tahun >= 2024">
                      <div v-if="itemsTahunan.eselon != 0">
                        <v-select
                          v-if="formEdit.is_sakip"
                          ref="syncProgramIndikator"
                          dense
                          v-model="formEdit.indikator_kuantitas"
                          :items="listIndikator"
                          @change="changeTarget"
                          item-text="text"
                          item-value="id"
                          label="Indikator"
                          outlined
                        >
                          <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            <div
                              align="left"
                              justify="center"
                              class="mt-2 mb-0"
                            >
                              {{ data.item.text }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <!-- <v-text-field v-if="formEdit.is_sakip" dense v-model="formEdit.indikator_kuantitas" :disabled="!formEdit.is_sakip" outlined label="Indikator Kuantitas"></v-text-field> -->
                      <div v-if="itemsTahunan.eselon != 0">
                        <v-text-field
                          v-if="!formEdit.is_sakip"
                          dense
                          v-model="formEdit.indikator_kuantitas"
                          :rules="[rules.required]"
                          outlined
                          label="Indikator"
                        ></v-text-field>
                      </div>
                      <div v-else>
                        <v-text-field
                          dense
                          v-model="formEdit.indikator_kuantitas"
                          :rules="[rules.required]"
                          outlined
                          label="Indikator"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div v-if="tahun < 2024">
                      <v-text-field
                        v-if="formEdit.is_skp_iki == false"
                        ref="syncProgramTargetBawah"
                        dense
                        v-model="formEdit.target_bawah"
                        :disabled="!indikator_kuantitas"
                        :rules="
                          formEdit.indikator_kuantitas != null ||
                          indikator_kuantitas != ''
                            ? [rules.required]
                            : []
                        "
                        outlined
                        label="Target"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        dense
                        v-model="formEdit.target_bawah"
                        :rules="
                          formEdit.indikator_kuantitas != null ||
                          indikator_kuantitas != ''
                            ? [rules.required, rules.onlyInt]
                            : []
                        "
                        outlined
                        label="Target"
                      ></v-text-field>
                    </div>
                    <div v-if="tahun >= 2024">
                      <v-text-field
                        v-if="formEdit.is_sakip"
                        ref="syncProgramTargetBawah"
                        dense
                        v-model="formEdit.target_bawah"
                        :disabled="!indikator_kuantitas"
                        :rules="
                          formEdit.indikator_kuantitas != null ||
                          indikator_kuantitas != ''
                            ? [rules.required]
                            : []
                        "
                        outlined
                        label="Target"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        dense
                        v-model="formEdit.target_bawah"
                        :rules="
                          formEdit.indikator_kuantitas != null ||
                          indikator_kuantitas != ''
                            ? [rules.required, rules.onlyInt]
                            : []
                        "
                        outlined
                        label="Target"
                      ></v-text-field>
                    </div>
                    <!-- <v-text-field v-else dense v-model="formEdit.target_bawah" :disabled="!indikator_kuantitas" :rules="formEdit.indikator_kuantitas != null || || indikator_kuantitas != '' ? [rules.required, rules.onlyInt] : []" outlined label="Target Batas Bawah"></v-text-field> -->
                  </v-col>
                  <!-- <v-col cols="3" v-if="formEdit.is_skp_iki">
                    <v-text-field dense v-model="formEdit.target_atas" :disabled="!formEdit.target_bawah" outlined label="Target Batas Atas"></v-text-field>
                  </v-col> -->
                  <v-col cols="2">
                    <div v-if="tahun < 2024">
                      <v-select
                        ref="syncProgramSatuanTarget"
                        dense
                        v-model="formEdit.satuan_target"
                        :rules="
                          formEdit.indikator_kuantitas != null
                            ? [rules.required]
                            : []
                        "
                        outlined
                        :items="satuanSKP"
                        label="Satuan"
                      ></v-select>
                    </div>
                    <div v-if="tahun >= 2024">
                      <v-select
                        ref="syncProgramSatuanTarget"
                        dense
                        v-model="formEdit.satuan_target"
                        :rules="
                          formEdit.indikator_kuantitas != null
                            ? [rules.required]
                            : []
                        "
                        outlined
                        :items="satuanSKP"
                        label="Satuan"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!-- <v-select dense v-model="formEdit.id_tupoksi" :rules="[rules.required]" :items="listTupoksi" item-text="text" item-value="id" label="Tupoksi" outlined ></v-select> -->

              <!-- Penambahan perspektif Permenpan 6 untuk JPT -->
              <v-select
                v-if="itemsTahunan.eselon == 2 || isPLT"
                dense
                v-model="formEdit.perspektif"
                :items="listPerspektif"
                :rules="[rules.required]"
                item-text="text"
                item-value="value"
                label="Perspektif"
                outlined
              ></v-select>
              <!-- <v-select v-else disabled dense v-model="formEdit.perspektif" :items="listPerspektif" item-text="text" item-value="value" label="Perspektif" outlined ></v-select> -->

              <v-row align="center">
                <span class="mx-3">Rumus Penurunan</span>
                <v-checkbox
                  v-model="formEdit.is_pembalik"
                  label="Nilai Pembalik"
                ></v-checkbox>
              </v-row>

              <v-row align="center">
                <span class="mx-3">Direct Cascading</span>
                <v-checkbox
                  class="mx-3"
                  style="margin-top: 0px !important"
                  v-model="formEdit.direct_cascading"
                  label="Centang pilihan direct cascading untuk rencana kinerja yang dapat dibagi berdasarkan aspek, wilayah, dan/atau beban target kuantitatif "
                ></v-checkbox>
              </v-row>

              <!-- <h1 class="display-2 my-2">Indikator Keuangan</h1>
              <v-text-field dense :value="formEdit.kegiatan" outlined  label="Kegiatan" disabled></v-text-field>
              <v-text-field dense :value="formEdit.pagu" outlined label="Pagu" disabled></v-text-field> -->
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogEditSKPPermenpan6 = false">Tutup</v-btn>
          <v-btn
            color="success"
            :loading="loadingBtn.editSKP"
            @click="simpanItem()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog pengaturan SKP -->
    <v-dialog v-model="dialogPengaturanSKP" persistent max-width="950px">
      <v-card>
        <v-card-title>
          <span class="headline">Pengaturan SKP</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <h1 class="display-2">Penilai</h1>
            <v-divider />
            <v-form ref="formPengaturan" class="mt-4">
              <v-row align="center">
                <v-col class="d-flex" cols="5">
                  <v-text-field
                    label="NIP Atasan"
                    outlined
                    counter="18"
                    v-model="formPengaturan.nip_atasan"
                    @change="cariAtasan"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                  <v-progress-circular
                    indeterminate
                    class="ma-2"
                    v-if="loadingNIP1"
                    color="primary"
                  ></v-progress-circular>
                  <debug-btn :item="nip1"></debug-btn>
                </v-col>
                <v-col>
                  <v-radio-group
                    class="mt-0"
                    v-model="formPengaturan.jenis_atasan"
                    row
                  >
                    <v-radio label="PLT" value="radio-1"></v-radio>
                    <v-radio label="PLH" value="radio-2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-btn
                    color="green darken-1"
                    dark
                    @click="getAtasan()"
                    :loading="loadGetAtasan"
                    class="mb-7"
                    >Get Penilai</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn @click="clearPenilai()" class="mb-7">Clear</v-btn>
                </v-col>
              </v-row>
              <!-- <v-row>
              <v-col>
                <v-radio-group class="mt-0" v-model="formPengaturan.jenis_atasan" row>
                  <v-radio label="PLT" value="radio-1"></v-radio>
                  <v-radio label="PLH" value="radio-2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row> -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    :value="formPengaturan.nama_atasan"
                    disabled
                    label="Nama Atasan"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    disabled
                    :value="formPengaturan.golongan_atasan"
                    label="Golongan"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.jabatan_atasan"
                    label="Jabatan Atasan"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.unit_kerja_atasan"
                    label="Unit Kerja"
                  ></v-text-field>
                </v-col>
              </v-row>

              <h1 class="display-2">Atasan Penilai</h1>
              <v-divider />
              <v-row class="mt-4">
                <v-col class="d-flex align-top" cols="4">
                  <v-text-field
                    outlined
                    counter="18"
                    :rules="[rules.required]"
                    v-model="formPengaturan.nip_atasan_penilai"
                    @change="cariAtasanPenilai"
                    label="NIP Atasan"
                    required
                  ></v-text-field>
                  <v-progress-circular
                    indeterminate
                    class="ma-2"
                    v-if="loadingNIP2"
                    color="primary"
                  ></v-progress-circular>
                  <debug-btn :item="nip2"></debug-btn>
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="formPengaturan.jenis_atasan_penilai"
                    row
                  >
                    <v-radio label="PLT" value="radio-1"></v-radio>
                    <v-radio label="PLH" value="radio-2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-btn
                    color="green darken-1"
                    dark
                    @click="getAtasanPenilai()"
                    class="mb-7"
                    :loading="loadGetAtasanPenilai"
                    >Get Atasan Penilai</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn @click="clearAtasanPenilai()" class="mb-7"
                    >Clear</v-btn
                  >
                </v-col>
              </v-row>
              <!-- <v-row>
              <v-col>
                <v-radio-group v-model="formPengaturan.jenis_atasan_penilai" row>
                  <v-radio label="PLT" value="radio-1"></v-radio>
                  <v-radio label="PLH" value="radio-2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row> -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.nama_atasan_penilai"
                    disabled
                    label="Nama Atasan"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.golongan_atasan_penilai"
                    disabled
                    label="Golongan"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.jabatan_atasan_penilai"
                    label="Jabatan Atasan"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.unit_kerja_atasan_penilai"
                    label="Unit Kerja"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div v-if="tahun >= 2022">
                <h1 class="display-2">Periode</h1>
                <v-divider />
                <v-row align="center" class="mt-4">
                  <v-col cols="12" sm="2">
                    <v-subheader v-text="'Periode'"></v-subheader>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-select
                      outlined
                      v-model="formPengaturan.triwulan"
                      item-text="b"
                      item-value="k"
                      :items="triwulanList"
                      :menu-props="{ maxHeight: '400' }"
                      :rules="[rules.required]"
                      label="Triwulan"
                      @change="changePeriode"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" sm="2">
                    <v-subheader v-text="'Bulan'"></v-subheader>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-select
                      outlined
                      v-model="formPengaturan.periode_awal"
                      item-text="b"
                      item-value="k"
                      :items="bulanList"
                      :menu-props="{ maxHeight: '400' }"
                      :rules="[rules.required]"
                      label="Bulan"
                      :disabled="disabledPeriode_"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-select
                      outlined
                      v-model="formPengaturan.periode_akhir"
                      item-text="b"
                      item-value="k"
                      :items="bulanList"
                      :menu-props="{ maxHeight: '400' }"
                      :rules="[rules.required]"
                      label="Bulan"
                      :disabled="disabledPeriode_"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <h1 class="display-2">Periode</h1>
                <v-divider />
                <v-row align="center">
                  <v-col cols="12" sm="2">
                    <v-subheader v-text="'Periode'"></v-subheader>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-select
                      outlined
                      v-model="formPengaturan.periode_awal"
                      item-text="b"
                      item-value="k"
                      :items="bulanList"
                      :menu-props="{ maxHeight: '400' }"
                      :rules="[rules.required]"
                      label="Bulan"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-select
                      outlined
                      v-model="formPengaturan.periode_akhir"
                      item-text="b"
                      item-value="k"
                      :items="bulanList"
                      :menu-props="{ maxHeight: '400' }"
                      :rules="[rules.required]"
                      label="Bulan"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>

              <h1 class="display-2">Jabatan</h1>
              <v-divider />
              <v-row class="mt-4">
                <v-col>
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.jabatan_nama"
                    label="Jabatan"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    :rules="[rules.required]"
                    v-model="formPengaturan.unit_kerja"
                    label="Unit Kerja"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogPengaturanSKP = false">Tutup</v-btn>
          <v-btn color="green darken-1" dark @click="simpanPengaturan()"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTutupSKP" max-width="600px">
      <v-card>
        <v-card-title> Lapor dan Tutup SKP </v-card-title>
        <v-card-text>
          Anda akan melakukan tutup SKP tahunan, Apakah anda yakin?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              dialogTutupSKP = false;
              enableLapor = false;
            "
            >Tidak</v-btn
          >
          <v-btn
            color="primary"
            dark
            @click="
              dialogTutupSKP = false;
              enableLapor = true;
            "
            >Ya</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLapor" max-width="600">
      <v-card>
        <v-card-text>
          <v-form ref="formLapor">
            <h1 class="display-2 my-2">Sasaran Kinerja Pegawai</h1>
            <div class="my-2">
              <span class="label font-weight-light">Jenis</span>
              <div class="font-weight-regular">Tahunan</div>
            </div>
            <div class="my-2">
              <span class="label font-weight-light">Sasaran Strategis</span>
              <div class="font-weight-regular">
                {{ defaultStrip(formLapor.sasaran_strategis) }}
              </div>
            </div>
            <div class="my-2">
              <span class="label font-weight-light">Tupoksi</span>
              <div class="font-weight-normal">
                {{ defaultStrip(formLapor.tupoksi) }}
              </div>
            </div>
            <div class="my-2">
              <span class="label font-weight-light">SKP Atasan Langsung</span>
              <div class="font-weight-normal">
                {{ defaultStrip(formLapor.skp_atasan) }}
              </div>
            </div>
            <div class="my-2" v-if="formLapor.format_skp == 'baru'">
              <span class="label font-weight-light"
                >Sasaran Kinerja Tahunan</span
              >
              <div class="font-weight-regular">
                {{ defaultStrip(formLapor.sasaran_kinerja_tahunan) }}
              </div>
            </div>
            <div class="my-2" v-else>
              <span class="label font-weight-light"
                >Rencana Kinerja Tahunan</span
              >
              <div class="font-weight-regular">
                {{ defaultStrip(formLapor.rencana_kinerja) }}
              </div>
            </div>
            <div v-if="formLapor.format_skp == 'baru'">
              <v-row>
                <v-col>
                  <div>
                    <span class="label font-weight-light">Nilai Pembalik</span>
                    <div class="font-weight-regular">
                      {{ defaultStrip(formLapor.np) }}
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <span class="label font-weight-light"
                      >Metode Cascading</span
                    >
                    <div class="font-weight-regular">
                      {{
                        formLapor.direct_cascading == true
                          ? "Direct"
                          : "Non-direct"
                      }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="formLapor.format_skp == 'lama'">
              <v-row>
                <v-col>
                  <div>
                    <span class="label font-weight-light">Nilai Pembalik</span>
                    <div class="font-weight-regular">
                      {{ defaultStrip(formLapor.np) }}
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <span class="label font-weight-light">Waktu</span>
                    <div class="font-weight-regular">
                      {{ defaultStrip(formLapor.waktu) }}
                      {{ formLapor.satuan_waktu }}
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <span class="label font-weight-light">Kualitas</span>
                    <div class="font-weight-regular">
                      {{ defaultStrip(formLapor.kualitas) }} %
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-if="formLapor.format_skp == 'baru'">
              <v-row
                v-if="
                  formLapor.target_bawah != '' && formLapor.target_bawah != null
                "
              >
                <v-col>
                  <span class="label font-weight-light"
                    >Target Kuantitas :
                    {{
                      formLapor.target_atas != null
                        ? formLapor.target_bawah + "-" + formLapor.target_atas
                        : formLapor.target_bawah
                    }}
                    {{ formLapor.satuan_target }}</span
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="formLapor.realisasi_kuantitas"
                    hint="Gunakan titik [.] sebagai pengganti koma Contoh: 9.8"
                    :rules="
                      formLapor.format_skp == 'baru' && formLapor.target_bawah
                        ? [rules.required]
                        : []
                    "
                    outlined
                    label="Realisasi"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  formLapor.kualitas_batas_bawah != '' &&
                  formLapor.kualitas_batas_bawah != null
                "
              >
                <v-col>
                  <span class="label font-weight-light"
                    >Target Kualitas :
                    {{
                      formLapor.kualitas_batas_atas != null
                        ? formLapor.kualitas_batas_bawah +
                          "-" +
                          formLapor.kualitas_batas_atas
                        : formLapor.kualitas_batas_bawah
                    }}
                    %</span
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="formLapor.realisasi_kualitas"
                    hint="Gunakan titik [.] sebagai pengganti koma Contoh: 9.8"
                    :rules="
                      formLapor.format_skp == 'baru' &&
                      formLapor.kualitas_batas_bawah
                        ? [rules.required, rules.onlyInt]
                        : []
                    "
                    outlined
                    label="Realisasi"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  formLapor.waktu_batas_bawah != '' &&
                  formLapor.waktu_batas_bawah != null
                "
              >
                <v-col>
                  <span class="label font-weight-light"
                    >Target Waktu :
                    {{
                      formLapor.waktu_batas_atas != null
                        ? formLapor.waktu_batas_bawah +
                          "-" +
                          formLapor.waktu_batas_atas
                        : formLapor.waktu_batas_bawah
                    }}
                    bulan</span
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="formLapor.realisasi_waktu"
                    hint="Gunakan titik [.] sebagai pengganti koma Contoh: 9.8"
                    :rules="
                      formLapor.format_skp == 'baru' &&
                      formLapor.waktu_batas_bawah
                        ? [rules.required, rules.onlyInt]
                        : []
                    "
                    outlined
                    label="Realisasi"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div v-if="formLapor.format_skp == 'lama'">
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="formLapor.target"
                    :rules="[rules.required]"
                    outlined
                    label="Target"
                    :suffix="formLapor.satuan_target"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="formLapor.realisasi"
                    hint="Gunakan titik [.] sebagai pengganti koma Contoh: 9.8"
                    :rules="
                      formLapor.format_skp != 'baru' ? [rules.required] : []
                    "
                    :suffix="formLapor.satuan_target"
                    outlined
                    label="Realisasi"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-text-field
              v-if="formLapor.format_skp != 'baru'"
              dense
              disabled
              v-model="formLapor.capaian"
              outlined
              label="Capaian"
              append-icon="mdi-percent"
              hint="Persentase realisasi terhadap target"
            ></v-text-field>

            <div v-if="tahun >= 2022">
              <v-checkbox
                v-model="formLapor.lepas_jabatan"
                label="Alih Tugas/Rotasi/Mutasi"
              ></v-checkbox>
            </div>

            <v-select
              outlined
              v-model="formLapor.id_skp_konfigurasi"
              item-text="periode"
              item-value="id_sasaran_kinerja_konfigurasi"
              :items="listPeriodeSKP"
              :menu-props="{ maxHeight: '400' }"
              :rules="[rules.required]"
              label="Periode SKP"
            ></v-select>

            <file-input
              ref="fileInput"
              :errorInput="fileInputErrorFlag"
              :uploadUrl="uploadUrl"
              @delFileUploaded="delFormFile"
              @fileUploaded="addFormFile"
            ></file-input>

            <v-divider></v-divider>
            <h1 class="display-2 my-2">Laporan Data Dan Pengukuran Kinerja</h1>

            <div class="my-2">
              <span class="label font-weight-light"
                >Formulasi Pengukuran, Alasan, dan Kriteria</span
              >
              <div class="font-weight-regular">
                {{ defaultStrip(formLapor.formulasi) }} %
              </div>
            </div>

            <v-textarea
              v-model="data_relevan"
              outlined
              label="Penjelasan Realisasi Dengan Target Kinerja Tahunan *"
              required
              hint="Wajib Diisi ! Minimal 25 karakter"
              :rules="[rules.required, rules.min25]"
            ></v-textarea>
            <!-- CATATAN TAMBAHAN by ISMAIL 10/06/2024 REQUEST BAPPELITBANG -->
            <!-- <v-textarea
              v-model.lazy="formLapor.hambatan"
              outlined
              label="Hambatan Yang Terjadi Untuk Memenuhi Target Kinerja Triwulan *"
              required
              hint="Wajib Diisi ! Minimal 25 karakter"
              :rules="[rules.required, rules.min25]"
            ></v-textarea> -->

            <v-textarea
              v-model="pengukuran_kinerja"
              outlined
              label="Pengukuran Kinerja *"
              required
              hint="Wajib Diisi ! Minimal 25 karakter"
              :rules="[rules.required, rules.min25]"
            ></v-textarea>

            <div v-if="tahun >= 2023">
              <v-textarea
                v-model.lazy="formLapor.hambatan"
                outlined
                label="Hambatan Yang Terjadi Untuk Memenuhi Target Kinerja Triwulan *"
                required
                hint="Wajib Diisi ! Minimal 25 karakter"
                :rules="
                  formLapor.format_skp == 'baru'
                    ? [rules.required, rules.min25]
                    : []
                "
              ></v-textarea>
            </div>

            <v-divider></v-divider>
            <h1 class="display-2 my-2">Indikator Kinerja</h1>
            <div class="my-2">
              <span class="label font-weight-light">Jenis SKP</span>
              <div class="font-weight-regular">
                {{ defaultStrip(formLapor.jenis_skp) }}
              </div>
            </div>
            <div class="my-2">
              <span class="label font-weight-light"
                >Indikator Kegiatan/Ouput/Keluaran</span
              >
              <div class="font-weight-regular">
                {{ defaultStrip(formLapor.indikator) }}
              </div>
            </div>

            <!-- <v-divider></v-divider>
          <h1 class="display-2 my-2">Indikator Keuangan</h1>
          <div class="my-2">
            <span class="label font-weight-light">Kegiatan</span>
            <div class="font-weight-regular">{{defaultStrip(formLapor.kegiatan)}}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Pagu</span>
            <div class="font-weight-regular">{{defaultStrip(formLapor.pagu)}}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Realisasi</span>
            <div class="font-weight-regular">{{defaultStrip(formLapor.realisasi_pagu)}}</div>
          </div> -->
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="kirimLaporan()"
            :loading="loadingBtn.laporkan"
            class="success"
            dark
            >Laporkan</v-btn
          >
          <v-btn @click="dialogLapor = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFormAssignmentSKP" persistent>
      <FormAssignmentSKP
        :dataPegawai="dataPegawai"
        :inputs_="inputs_"
        @onClose="
          (x) => {
            dialogFormAssignmentSKP = x;
          }
        "
        @onTableChange="updateTableTahunan"
      />
    </v-dialog>

    <v-dialog v-model="dialogFormLampiranAngkaKreditSKP" persistent>
      <FormLampiranAngkaKreditSKP
        :dataPegawai="dataPegawai"
        :rowDataAngkaKredit="rowDataAngkaKredit"
        @onClose="
          (x) => {
            dialogFormLampiranAngkaKreditSKP = x;
          }
        "
        @onTableChange="updateTableTahunan"
      />
    </v-dialog>

    <v-dialog v-model="dialogLampiranSKP" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Form Lampiran SKP Permenpan 6 2022</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="10" align="left">
              <h1 class="float-left display-1 my-1">
                Lengkapi Lampiran SKP Anda Agar Pimpinan Dapat Memperhatikan
                Kebutuhan Anda, Skema Pertanggungjawaban Anda, dan Konsekuensi
                Dalam Mencapai Target Kinerja Yang Diberikan.
              </h1>
            </v-col>
            <v-col cols="2" align="right">
              <v-btn @click="addRow()">Tambah Baris</v-btn>
              <!-- <v-btn color="error" @click="removeRow(key)">Hapus</v-btn> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Dukungan Sumber Daya</th>
              <th>Skema Pertanggungjawaban</th>
              <th>Konsekuensi</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="rowData.length > 0">
              <tr v-for="(item, key) in rowData" v-bind:key="key.no">
                <td>
                  <v-textarea
                    v-model="item.dukungan_sumber_daya"
                    :label="'Dukungan Sumber Daya Ke-' + (key + 1)"
                    outlined
                  ></v-textarea>
                </td>
                <td>
                  <v-textarea
                    v-model="item.skema_pertanggungjawaban"
                    :label="'Skema Pertanggungjawaban Ke-' + (key + 1)"
                    outlined
                  ></v-textarea>
                </td>
                <td>
                  <v-textarea
                    v-model="item.konsekuensi"
                    :label="'Konsekuensi Ke-' + (key + 1)"
                    outlined
                  ></v-textarea>
                </td>
                <td>
                  <v-btn color="error" @click="removeItem(item)"
                    >Hapus Baris</v-btn
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="3" class="text-center">
                  <p class="grey--text">Data kosong</p>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="tutupFormLampiranSKP()" color="warning">tutup</v-btn>
          <v-btn
            @click="simpanLampiranSKP()"
            :loading="loadingBtn.lampiran"
            color="primary"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="tahun >= 2022"
      v-model="dialogFormEkspektasiPerilaku"
      :persistent="dialogFormEkspektasiPerilakuPersistent"
    >
      <FormEkspektasiPerilaku
        :aria-readonly="readOnlyFormEkspektasi"
        :triwulan="null"
        :dataPegawaiEkspektasiPerilaku="dataPegawaiEkspektasiPerilaku"
        @onClose="
          (x) => {
            dialogFormEkspektasiPerilaku = x;
          }
        "
        @onTableChange="updateTableTahunan"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import store from "@/store";
import Crud from "@/components/common/Crud";
import ConfirmBtn from "@/components/base/ConfirmBtn";
import FileInput from "@/components/common/FileInput";
import FeedbackBtn from "./components/FeedbackBtn";
import DebugBtn from "@/components/common/DebugBtn";
import SKPService from "@/services/SKPService";
import { obj2Arr, defaultRules } from "@/utils/lib";
import FormAssignmentSKP from "./FormAssignmentSKP.vue";
import FormLampiranAngkaKreditSKP from "./FormLampiranAngkaKreditSKP.vue";
import DataTablePengaturanSKP from "@/components/common/DataTablePengaturanSKP";
import FormEkspektasiPerilaku from "./FormEkspektasiPerilaku.vue";
import _g from "@/global";
import axios from "axios";
import { local } from "@/store/local";

export default {
  components: {
    Crud,
    ConfirmBtn,
    FileInput,
    FeedbackBtn,
    DebugBtn,
    FormAssignmentSKP,
    FormLampiranAngkaKreditSKP,
    DataTablePengaturanSKP,
    FormEkspektasiPerilaku,
  },

  data() {
    return {
      enableAll: false, //override server flag

      nip1: {},
      nip2: {},

      forceUpdate: false,
      loading: false,
      loadingNIP1: false,
      loadingNIP2: false,
      enableLapor: false,
      dialogTambahSKP: false,
      dialogPengaturanSKP: false,
      dialogTutupSKP: false,
      dialogReset: false,
      dialogLapor: false,
      dialogFormatSKP: false,
      dialogFormatSKPBaru: null,
      dialogFormAssignmentSKP: false,
      disabledSKPAtasan: false,
      dialogFormLampiranAngkaKreditSKP: false,

      fileInputErrorFlag: false,

      rules: {
        ...defaultRules,
      },
      triwulanList: [
        { b: "Triwulan I (Januari-Maret)", k: 1 },
        { b: "Triwulan II (April-Juni)", k: 2 },
        { b: "Triwulan III (Juli-September)", k: 3 },
        { b: "Triwulan IV (Oktober-Desember)", k: 4 },
        { b: "Tahunan (Januari-Desember)", k: 5 },
        { b: "Alih Tugas/Rotasi/Mutasi", k: 6 },
      ],
      bulanList: [
        { b: "Januari", k: 1 },
        { b: "Febuari", k: 2 },
        { b: "Maret", k: 3 },
        { b: "April", k: 4 },
        { b: "Mei", k: 5 },
        { b: "Juni", k: 6 },
        { b: "Juli", k: 7 },
        { b: "Agustus", k: 8 },
        { b: "September", k: 9 },
        { b: "Oktober", k: 10 },
        { b: "November", k: 11 },
        { b: "Desember", k: 12 },
      ],
      loadingBtn: {
        syncBtn: false,
        pengaturan: false,
        laporkan: false,
        lampiran: false,
        syncSakipProgram: false,
        syncSakipKegiatan: false,
        syncSakipSubKegiatan: false,
        tambahSKP: false,
        editSKP: false,
      },
      formPengaturan: {},
      dataPengaturan: {},
      formEdit: {},
      formLapor: {},

      //table tahunan
      tableValuesTahunan: { itemPerPage: 10, page: 1 },
      loadingTahunan: false,
      headersJPT: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Misi/Tujuan', value:'skp_atasan', sortable:false, class:'header-index-profile', width:"25%"},
        {
          text: "Rencana Kinerja",
          value: "sasaran_kinerja_tahunan",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Kriteria', value:'kriteria', sortable:false, class:'header-index-profile'},
        {
          text: "Indikator Kinerja",
          value: "indikator",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Status SKP",
          value: "teks_status",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Target Output",
          value: "target",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Realisasi Capaian",
          value: "realisasi_capaian",
          sortable: false,
          class: "header-index-profile",
        },
        { value: "aksi", sortable: false, class: "header-index-profile" },
      ],
      headersTahunan: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Rencana Kinerja Atasan Langsung/Yang Diintervensi",
          value: "skp_atasan",
          sortable: false,
          class: "header-index-profile",
          width: "25%",
        },
        {
          text: "Rencana Kinerja",
          value: "sasaran_kinerja_tahunan",
          sortable: false,
          class: "header-index-profile",
          width: "25%",
        },
        // {text:'Kriteria', value:'kriteria', sortable:false, class:'header-index-profile'},
        {
          text: "Indikator Kinerja",
          value: "indikator",
          sortable: false,
          class: "header-index-profile",
          width: "25%",
        },
        {
          text: "Status SKP",
          value: "teks_status",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Target Output",
          value: "target",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Realisasi Capaian",
          value: "realisasi_capaian",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
        { value: "aksi", sortable: false, class: "header-index-profile" },
      ],
      headersTahunan_: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Sasaran Strategis",
          value: "indikator",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Sasaran Kinerja",
          value: "sasaran_kinerja_tahunan",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Status",
          value: "teks_status",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Target Output",
          value: "target",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
        { value: "aksi", sortable: false, class: "header-index-profile" },
      ],
      headersJFT: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Rencana Kinerja Atasan Langsung/Intervensi",
          value: "skp_atasan",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Rencana Kinerja",
          value: "sasaran_kinerja_tahunan",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Indikator Kinerja",
          value: "indikator",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Status SKP",
          value: "teks_status",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Target Output",
          value: "target",
          sortable: false,
          class: "header-index-profile",
        },
        // {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
        { value: "aksi", sortable: false, class: "header-index-profile" },
      ],
      itemsTahunan: {},

      // table dilaporkan
      tableValuesDilaporkan: { itemPerPage: 10, page: 1 },
      loadingDilaporkan: false,
      headersDilaporkan: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Tanggal Lapor",
          value: "tanggal_lapor",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Periode",
          value: "periode",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Sasaran Strategis",
          value: "indikator",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Sasaran Kinerja",
          value: "sasaran_kinerja_tahunan",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Target Output",
          value: "target",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Waktu",
          value: "waktu",
          sortable: false,
          class: "header-index-profile",
        },
        { value: "aksi", sortable: false, class: "header-index-profile" },
      ],
      itemsDilaporkan: {},

      // table pengaturan SKP
      tableValuesPengaturanSKP: { itemPerPage: 10, page: 1 },
      loadingPengaturanSKP: false,
      headersPengaturanSKP: [
        {
          text: "No",
          value: "nomer",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Periode SKP",
          value: "periode_skp",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Anda",
          value: "nip",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Penilai",
          value: "nip_atasan",
          sortable: false,
          class: "header-index-profile",
        },
        {
          text: "Atasan Penilai",
          value: "nip_atasan_penilai",
          sortable: false,
          class: "header-index-profile",
        },
        { value: "aksi", sortable: false, class: "header-index-profile" },
      ],
      itemsPengaturanSKP: {},

      detailData: {},
      crudSettings: {
        title: "",
        detailTitle: "Detail",
        deleteTitleKey: "peg_nama",
        enableHeader: true,
        enableDetail: false,
        enableCreate: false,
        enableEdit: false,
        enableDelete: false,
      },
      listSasaranStrategis: [],
      tempListSasaranStrategis: [],
      listTupoksi: [],
      listKegiatan: [],
      listSKPAtasan: [],
      satuanSKP: [],
      listAspek2: ["Kuantitas", "Kualitas", "Waktu"],
      formatSKP: "",
      dataPegawai: null,
      inputs_: [],
      eselon: null,
      jft: null,
      rowDataAngkaKredit: [],
      listPeriodeSKP: [],
      open: false,
      rowData: [],
      no: 0,
      listPerspektif: [
        {
          value: "Perspektif penerima layanan",
          text: "Perspektif penerima layanan",
        },
        { value: "Perspektif proses bisnis", text: "Perspektif proses bisnis" },
        {
          value: "Perspektif penguatan internal",
          text: "Perspektif penguatan internal",
        },
        { value: "Perspektif anggaran", text: "Perspektif anggaran" },
      ],
      dialogLampiranSKP: false,
      dataPegawaiEkspektasiPerilaku: [],
      disabled_ekskpektasi: false,
      dialogFormEkspektasiPerilaku: false,
      readOnlyFormEkspektasi: false,
      dialogFormEkspektasiPerilakuPersistent: false,
      tahun: null,
      dialogTambahSKPPermenpan6: false,
      dataSKPAtasan: [],
      tempSKPAtasan: {},
      walikota: false,
      isEditPengaturanSKP: false,
      disabledPeriode_: true,
      loadGetAtasan: false,
      loadGetAtasanPenilai: false,
      listIndikator: [],
      listItemIndikator: [],
      tempIndikator: [],
      tempModel: {},
      dialogEditSKPPermenpan6: false,
      disabledForm: true,
      input_skp_dibebaskan_by_nip: false,
    };
  },

  computed: {
    indikator_kuantitas: {
      get() {
        return this.formEdit.indikator_kuantitas;
      },
      set: _.debounce(function (newValue) {
        this.formEdit.indikator_kuantitas = newValue;
      }, 100),
    },
    data_relevan: {
      get() {
        return this.text;
      },
      set: _.debounce(function (newValue) {
        this.formLapor.data_relevan = newValue;
      }, 100),
    },
    pengukuran_kinerja: {
      get() {
        return this.text;
      },
      set: _.debounce(function (newValue) {
        this.formLapor.pengukuran_kinerja = newValue;
      }, 100),
    },
    skp_atasan: {
      get() {
        return this.formEdit.id_skp_atasan != undefined
          ? this.formEdit.id_skp_atasan
          : this.text;
      },
      set: _.debounce(function (newValue) {
        this.formEdit.id_skp_atasan = newValue;
      }, 100),
    },
    nilaiBulan() {
      return _.range(1, 13);
    },

    uploadUrl() {
      // return process.env.VUE_APP_API_URL + '/api/skp-tahunan-upload-evidence'
      return (
        process.env.VUE_APP_API_URL + "/api/ms/2/upload-evidence-skp-tahunan"
      );
    },

    isPLT() {
      return _g.isPLT();
    },
  },

  watch: {
    dialogLapor: function (val) {
      if (val) {
        setTimeout(() => {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
        }, 500);
      }
    },

    "formEdit.kode_kegiatan": function (val) {
      if (val && this.listKegiatan.length > 0) {
        console.log(this.listKegiatan);
        console.log(val);
        let kgt = this.listKegiatan.find((x) => x.id == val);
        this.formEdit.kegiatan = kgt.text;
        this.formEdit.indikator = kgt.text;
        console.log(kgt.biaya.toLocaleString());
        this.formEdit.pagu = (kgt.biaya + "").toLocaleString();
      } else {
        this.formEdit.indikator =
          this.formEdit.indikator == null ? "-" : this.formEdit.indikator;
      }
    },

    "formPengaturan.nip_atasan": function (val) {
      if (!this.isEditPengaturanSKP) {
        if (val.length == 18 || val.includes("PLT")) {
          this.loadingNIP1 = true;
          this.cariNIP(val)
            .then((data) => {
              this.nip1 = _.clone(data);
              this.formPengaturan.nama_atasan = data.peg_nama;
              this.formPengaturan.golongan_atasan =
                data.nm_pkt + ", " + data.nm_gol;
              this.formPengaturan.jabatan_atasan = data.jabatan_nama_atasan;
              this.formPengaturan.unit_kerja_atasan = data.unit_kerja;
            })
            .finally(() => {
              this.loadingNIP1 = false;
            });
        }
      }
    },

    "formPengaturan.nip_atasan_penilai": function (val) {
      if (!this.isEditPengaturanSKP) {
        if (val.length == 18 || val.includes("PLT")) {
          this.loadingNIP2 = true;
          this.cariNIP(val)
            .then((data) => {
              this.nip2 = _.clone(data);
              this.formPengaturan.nama_atasan_penilai = data.peg_nama;
              this.formPengaturan.golongan_atasan_penilai =
                data.nm_pkt + ", " + data.nm_gol;
              this.formPengaturan.jabatan_atasan_penilai =
                data.jabatan_nama_atasan;
              this.formPengaturan.unit_kerja_atasan_penilai = data.unit_kerja;
            })
            .finally(() => {
              this.loadingNIP2 = false;
            });
        }
      }
    },
  },

  created() {
    this.tahun = this.$store.getters["user/data"].tahun;
    let username = this.$store.getters["user/data"].username;
    if (username == "walikotabdg") {
      this.walikota = true;
      this.menuTitle = "Sasaran Strategis Tahunan";
    } else {
      this.walikota = false;
      this.menuTitle = "SKP Tahunan";
    }
    var store_ = store.state.user.data;
    debugger;
    if (
      this.$store.getters["user/data"].open == "open" ||
      store_.open == "open" ||
      local.getLocal("super")
    ) {
      this.open = true;
    }
    // mengambil database
    SKPService.satuanSKP().then((response) => {
      this.satuanSKP = response.data.data.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    });

    SKPService.getTupoksi().then((response) => {
      this.listTupoksi = response.data.data;
    });

    SKPService.getSasaranStrategis().then((response) => {
      let res = response.data;
      // this.listSasaranStrategis = response.data.data
      this.listSasaranStrategis = obj2Arr(res.data);
    });

    SKPService.getKegiatan().then((response) => {
      this.listKegiatan = response.data.data;
    });

    SKPService.getSKPAtasan().then((response) => {
      let res = response.data;
      this.listSKPAtasan = obj2Arr(res.data);
      this.dataSKPAtasan = res.data;
    });

    let val = { itemsPerPage: 10, page: 1 };
    this.updateTableTahunan(val);
    this.updateTableDilaporkan(val);
    this.updateTablePengaturanSKP(val);

    this.rowData = [];
    this.no = 0;
    if (this.tahun >= 2022) {
      this.updateLampiranSKP();
    }
  },

  mounted() {
    // 1. Jika nilai pembalik (is_pembalik) nya true, maka perhitungannya: target / realisasi * 100
    // 2. Jika is_pembaliknya false, perhitungannya: realisasi / target * 100
    this.$watch(
      (vm) => [
        vm.formLapor.target,
        vm.formLapor.realisasi,
        vm.formLapor.is_pembalik,
      ],
      (val) => {
        if (this.formLapor.is_pembalik) {
          this.formLapor.capaian = (
            (this.formLapor.target / this.formLapor.realisasi) *
            100
          ).toFixed(2);
        } else {
          this.formLapor.capaian = (
            (this.formLapor.realisasi / this.formLapor.target) *
            100
          ).toFixed(2);
        }
      }
    );
  },

  methods: {
    exportPPKPNS() {
      if (this.tahun >= 2022) {
        SKPService.insertPenilaianSKPPermenpan62022("-").then((response) => {
          let res = response.data;
          // Permenpan No 8 2021
          if (res.success) {
            var current = this.$store.getters["user/current"];
            var date = new Date();
            var bulan = date.getMonth();
            var year = current.year;
            if (this.tahun < 2022) {
              bulan = 11;
            }
            var urls =
              process.env.VUE_APP_API_URL +
              "/export-pdf/1/?tipe='tahunan'&thn=" +
              year +
              "&crc=" +
              res.crc;
            window.open(urls, "_blank");
          } else {
            store.commit("snackbar/setSnack", {
              message: res.message,
              color: "error",
            });
          }
        });
      } else {
        SKPService.insertPenilaianSKP().then((response) => {
          let res = response.data;
          // Permenpan No 8 2021
          if (res.success) {
            var current = this.$store.getters["user/current"];
            var date = new Date();
            var bulan = date.getMonth();
            var year = current.year;
            if (this.tahun < 2022) {
              bulan = 11;
            }
            var urls =
              process.env.VUE_APP_API_URL +
              "/export-pdf/1/?bln=" +
              (bulan + 1) +
              "&thn=" +
              year +
              "&crc=" +
              res.crc;
            window.open(urls, "_blank");
          } else {
            store.commit("snackbar/setSnack", {
              message: res.message,
              color: "error",
            });
          }
        });
      }
    },
    exportPenilaianKinerja() {
      var current = this.$store.getters["user/current"];
      var nip = current.username; //nip
      var year = current.year;
      // var role = current.role
      var urls = "";
      if (this.tahun >= 2022) {
        urls =
          "https://kinerja.bandung.go.id/" +
          year +
          "/api/export-tahunan-permenpan-6?nip=" +
          nip +
          "&api=1"; //permenpan 6 2022
      } else {
        urls =
          "https://kinerja.bandung.go.id/" +
          year +
          "/api/export-tahunan-format-baru?nip=" +
          nip +
          "&api=1"; //permenpan 8 2021
      }
      window.open(urls, "_blank");
    },
    addFormFile(file) {
      this.formLapor.file.push(file);
      // this.formLapor.file.push(file.name)
      console.log(this.formLapor.file);
    },

    delFormFile(file) {
      let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/;
      let filename = file.file.name;
      this.formLapor.file = this.formLapor.file.filter((val) => {
        const match = val.match(regex);
        let curr_filename = match[1] + "." + match[2];
        if (match) {
          return filename != curr_filename;
        }
        return false;
      });
    },

    defaultStrip(item) {
      if (!item) {
        return "-";
      }
      return item;
    },

    laporItem(item) {
      this.dialogLapor = true;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.clearAll();
      }
      this.formLapor = _.clone(item);
      this.formLapor.jenis = "tahun";
      this.formLapor.file = [];
      this.formLapor.periode_tutup_skp_tahunan =
        this.itemsTahunan.periode_tutup_skp;
      this.formLapor.periode_input_skp_tahunan =
        this.itemsTahunan.periode_input_skp;
      this.formLapor.np = this.formLapor.is_pembalik ? "Ya" : "Tidak";
      this.formLapor.format_skp = item.format_skp;
    },

    clearForm() {
      this.formEdit = {};
    },

    openDialogFormatSKP() {
      this.dialogFormatSKP = true;
      setTimeout(() => {
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      }, 500);
    },

    createItem(e) {
      if (this.jft != null) {
        this.disabledSKPAtasan = false;
      }
      this.formatSKP = e;
      this.clearForm();
      this.formEdit.format_skp = e;
      SKPService.getSKPAtasan(this.formEdit).then((response) => {
        let res = response.data;
        this.listSKPAtasan = obj2Arr(res.data);
      });
      setTimeout(() => {
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      }, 500);

      if (e == "per6/2022") {
        // this.dialogFormatSKPBaru = true
        this.disabledSKPAtasan = true;
        this.dialogTambahSKPPermenpan6 = true;
        this.formEdit.is_skp_iki = true;
      } else if (e == "baru") {
        this.dialogFormatSKPBaru = true;
        this.disabledSKPAtasan = true;
        this.dialogTambahSKP = true;
      } else {
        this.dialogFormatSKPBaru = false;
        this.disabledSKPAtasan = false;
        this.dialogTambahSKP = true;
      }
    },

    simpanItem() {
      var check = true;
      if (this.dialogEditSKPPermenpan6) {
        this.loadingBtn.editSKP = true;
        this.formEdit.target_bawah =
          this.formEdit.target_bawah == "" ? null : this.formEdit.target_bawah;
        this.formEdit.rencana_kinerja =
          this.formEdit.rencana_kinerja == ""
            ? null
            : this.formEdit.rencana_kinerja;
        this.formEdit.id_sasaran_strategis =
          this.formEdit.id_sasaran_strategis == ""
            ? null
            : this.formEdit.id_sasaran_strategis;
        this.formEdit.id_skp_atasan =
          this.formEdit.id_skp_atasan == ""
            ? null
            : this.formEdit.id_skp_atasan;
        this.formEdit.indikator_kuantitas =
          this.formEdit.indikator_kuantitas == ""
            ? null
            : this.formEdit.indikator_kuantitas;
        if (this.$refs.formEditSKPPermenpan6.validate()) {
          if (this.itemsTahunan.eselon == 2) {
            if (
              this.formEdit.target_bawah != null &&
              this.formEdit.rencana_kinerja != null &&
              this.formEdit.id_sasaran_strategis != null &&
              this.formEdit.indikator_kuantitas
            ) {
              this.formEdit.is_pembalik =
                this.formEdit.is_pembalik === undefined
                  ? false
                  : this.formEdit.is_pembalik;
              // this.formEdit.is_skp_iki = this.formEdit.is_skp_iki === undefined ? false : this.formEdit.is_skp_iki
              // this.formEdit.is_skp_iki = this.formEdit.is_sakip === true ? false : this.formEdit.is_skp_iki
              this.formEdit.perspektif =
                this.formEdit.perspektif === undefined
                  ? "-"
                  : this.formEdit.perspektif;
              this.formEdit.format_skp = "baru";
              this.formEdit.id_sasaran_strategis =
                this.formEdit.id_sasaran_strategis == "null"
                  ? null
                  : this.formEdit.id_sasaran_strategis;
              // var id_indikator_sakip = this.formEdit.indikator_kuantitas
              // this.listIndikator.forEach(el => {
              //   if(el.id == id_indikator_sakip){
              //     this.formEdit.indikator_kuantitas = el.text
              //   }
              // });
            } else {
              check = false;
            }
          } else {
            if (
              this.formEdit.target_bawah != null &&
              this.formEdit.rencana_kinerja != null &&
              this.formEdit.id_sasaran_strategis != null &&
              this.formEdit.id_skp_atasan != null &&
              this.formEdit.indikator_kuantitas
            ) {
              this.formEdit.is_pembalik =
                this.formEdit.is_pembalik === undefined
                  ? false
                  : this.formEdit.is_pembalik;
              // this.formEdit.is_skp_iki = this.formEdit.is_skp_iki === undefined ? false : this.formEdit.is_skp_iki
              this.formEdit.is_skp_iki =
                this.formEdit.is_sakip === true
                  ? false
                  : this.formEdit.is_skp_iki;
              this.formEdit.perspektif =
                this.formEdit.perspektif === undefined
                  ? "-"
                  : this.formEdit.perspektif;
              this.formEdit.format_skp = "baru";
              this.formEdit.id_sasaran_strategis =
                this.formEdit.id_sasaran_strategis == "null"
                  ? null
                  : this.formEdit.id_sasaran_strategis;
              var id_indikator_sakip = this.formEdit.indikator_kuantitas;
              this.listIndikator.forEach((el) => {
                if (el.id == id_indikator_sakip) {
                  this.formEdit.indikator_kuantitas = el.text;
                }
              });
            } else {
              check = false;
            }
          }
        } else {
          check = false;
        }
      } else {
        if (this.dialogTambahSKPPermenpan6) {
          this.loadingBtn.tambahSKP = true;
          if (this.$refs.formTambahSKPPermenpan6.validate()) {
            this.formEdit.is_pembalik =
              this.formEdit.is_pembalik === undefined
                ? false
                : this.formEdit.is_pembalik;
            this.formEdit.is_skp_iki =
              this.formEdit.is_skp_iki === undefined
                ? false
                : this.formEdit.is_skp_iki;
            this.formEdit.perspektif =
              this.formEdit.perspektif === undefined
                ? "-"
                : this.formEdit.perspektif;
            this.formEdit.format_skp = "baru";
            this.formEdit.kriteria =
              this.formEdit.kriteria != null ? this.formEdit.kriteria : "-";
          } else {
            check = false;
            // return false
          }
        } else {
          if (this.$refs.formTambah.validate()) {
            this.formEdit.is_pembalik =
              this.formEdit.is_pembalik === undefined
                ? false
                : this.formEdit.is_pembalik;
            this.formEdit.is_skp_iki =
              this.formEdit.is_skp_iki === undefined
                ? false
                : this.formEdit.is_skp_iki;
            this.formEdit.format_skp = this.formatSKP;
            this.formEdit.sasaran_kinerja_tahunan =
              this.formEdit.sasaran_kinerja_tahunan != null &&
              this.formEdit.sasaran_kinerja_tahunan != ""
                ? this.formEdit.sasaran_kinerja_tahunan
                : null;
            this.formEdit.target =
              this.formEdit.target != null && this.formEdit.target != ""
                ? this.formEdit.target
                : null;
          } else {
            check = false;
            // return false
          }
        }
      }
      this.formEdit.id_sasaran_strategis =
        this.formEdit.id_sasaran_strategis == "null"
          ? null
          : this.formEdit.id_sasaran_strategis;
      // SKPService.storeSKPTahunan(this.formEdit).then(response => {
      //   let res = response.data
      //   if (res.success){
      //     store.commit('snackbar/setSnack',{message: res.message, color:'success'})
      //     this.dialogTambahSKP = false
      //     this.dialogTambahSKPPermenpan6 = false

      //     if(this.dialogFormatSKP)this.dialogFormatSKP = false

      //     this.updateTableTahunan()
      //   } else {
      //     store.commit('snackbar/setSnack',{message: res.message, color:'error'})
      //   }
      // })
      if (check) {
        let current = store.state.user.current;
        let user = store.state.user;
        var base_url_api = _g.getBaseURLAPIERK(current.year);
        const url_store_skp_tahunan = base_url_api + "v1/store-skp-tahunan";
        if (
          user.current.role.includes("PLT") ||
          user.current.role.includes("Kepala UPT Puskesmas") ||
          user.current.role.includes("PLH")
        ) {
          current.username = _g.overrideWithPLT(user.current.role);
        } else {
          current.username = user.current.username;
        }
        this.formEdit.api = 1;
        this.formEdit.nip = current.username;
        axios
          .post(url_store_skp_tahunan, {
            ...this.formEdit,
          })
          .then((response) => {
            let res = response.data;
            if (res.success) {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "success",
              });
              this.dialogTambahSKP = false;
              this.dialogTambahSKPPermenpan6 = false;
              this.dialogEditSKPPermenpan6 = false;
              if (this.dialogFormatSKP) this.dialogFormatSKP = false;
              this.updateTableTahunan();
            } else {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "error",
              });
            }
          })
          .finally(() => {
            this.loadingBtn.tambahSKP = false;
            this.loadingBtn.editSKP = false;
            this.updateTableTahunan();
          })
          .catch((err) => {
            var error = err.message ? err.message : err.response.data.message;
            this.loadingEl = -1;
            store.commit("snackbar/setSnack", {
              message: error,
              color: "error",
            });
          });
      } else {
        this.loadingBtn.editSKP = false;
        this.loadingBtn.tambahSKP = false;
        return check;
      }
    },

    editItem(item) {
      this.formEdit = _.clone(item);
      if (item.perspektif != null && item.perspektif != "-") {
        // this.dialogTambahSKPPermenpan6 = true
        this.dialogEditSKPPermenpan6 = true;
        this.formEdit.format_skp = "baru";
        this.formatSKP = "baru";
        this.disabledSKPAtasan = true;
      } else if (
        (item.rencana_kinerja != null && item.rencana_kinerja != "") ||
        item.is_sakip
      ) {
        if (this.tahun == 2022) {
          if (this.formEdit.id_skp_atasan != null) {
            this.dataSKPAtasan.forEach((i) => {
              if (i.id != undefined) {
                if (i.id === this.formEdit.id_skp_atasan) {
                  this.tempSKPAtasan.id = i.id;
                  this.tempSKPAtasan.rencana_kinerja_tahunan =
                    i.rencana_kinerja_tahunan;
                  this.tempSKPAtasan.group = i.group;
                }
              }
            });
          } else {
            this.tempSKPAtasan = {};
          }
        }
        this.formEdit.format_skp = "baru";
        this.formatSKP = "baru";
        this.disabledSKPAtasan = true;
        this.dialogEditSKPPermenpan6 = true;
      } else {
        this.dialogFormatSKPBaru = false;
        this.formEdit.format_skp = "lama";
        this.formatSKP = "lama";
        this.disabledSKPAtasan = false;
        this.dialogTambahSKP = true;
      }
      // this.formEdit.id_skp_atasan = this.dataSKPAtasan.find(x => x.id === item.id_skp_atasan)
      this.formEdit.id_sasaran_strategis += "";
      // this.formEdit.id_skp_atasan += ""
      setTimeout(() => {
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      }, 500);
      if (this.jft != null) {
        this.disabledSKPAtasan = false;
      }
    },

    deleteItem(item) {
      SKPService.hapusSKPTahunan(item.id, this.open).then((response) => {
        let res = response.data;
        if (res.success) {
          store.commit("snackbar/setSnack", {
            message: res.message,
            color: "success",
          });
          // this.updateTableTahunan()
          this.updateBoth();
        } else {
          store.commit("snackbar/setSnack", {
            message: res.message,
            color: "error",
          });
        }
      });
    },

    kirimLaporan() {
      if (this.$refs.formLapor.validate()) {
        this.loadingBtn.laporkan = true;
        this.formLapor.lepas_jabatan =
          this.formLapor.lepas_jabatan === undefined ||
          this.formLapor.lepas_jabatan === null
            ? false
            : this.formLapor.lepas_jabatan;
        console.log("formLapor", this.formLapor);
        axios
          .post(_g.getBaseURLAPIERK(this.tahun, "v1/lapor-skp-tahunan"), {
            ...this.formLapor,
            api: 1,
            nip: this.formLapor.nip18,
            id: this.formLapor.id,
          })
          .then((response) => {
            let res = response.data;
            if (res.success) {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "success",
              });
              this.dialogLapor = false;
              this.updateTableTahunan();
              setTimeout(() => {
                this.updateTableDilaporkan();
              }, 2000);
            } else {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "error",
              });
            }
          })
          .finally(() => {
            this.loadingBtn.laporkan = false;
          })
          .catch((err) => {
            this.errorMsg = "Gagal mengirim laporan SKP Tahunan";
            this.loadingBtn.dataBerita = false;
          });

        // SKPService.laporSKPTahunan(this.formLapor).then(response=>{
        //   let res = response.data
        //   if (res.success){
        //     store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        //     this.dialogLapor = false
        //     this.updateTableTahunan()
        //     setTimeout(()=>{
        //       this.updateTableDilaporkan()
        //     }, 2000)
        //   }else {
        //     store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        //   }
        // }).finally(()=>{
        //   this.loadingBtn.laporkan = false
        // })
      }
    },

    syncSakip() {
      this.loadingBtn.syncBtn = true;
      SKPService.syncSakipTahunan()
        .then((response) => {
          console.log(response);
          let res = response.data;
          if (res.success) {
            this.updateTableTahunan();
            store.commit("snackbar/setSnack", {
              message: res.message,
              color: "success",
            });
          } else {
            if (res.warning) {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "warning",
              });
            } else {
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "error",
              });
            }
          }
        })
        .finally(() => {
          this.loadingBtn.syncBtn = false;
        });
    },

    syncSakipProgram() {
      this.loadingBtn.syncSakipProgram = true;
      let current = store.state.user.current;
      let user = store.state.user;
      var base_url_api = _g.getBaseURLAPIERK(current.year);
      const url_get_data = base_url_api + "v1/sync-skp-sakip-program";
      if (
        user.current.role.includes("PLT") ||
        user.current.role.includes("Kepala UPT Puskesmas") ||
        user.current.role.includes("PLH")
      ) {
        current.username = _g.overrideWithPLT(user.current.role);
      } else {
        current.username = user.current.username;
      }
      // this.tempListSasaranStrategis = this.listSasaranStrategis
      // this.listSasaranStrategis = []
      this.formEdit.target_bawah = "";
      axios
        .post(url_get_data, {
          api: 1,
          nip: current.username,
        })
        .then((response) => {
          if (response.data.success) {
            let res = response.data.data.data;
            res.forEach((el) => {
              this.listItemIndikator.push(el);
            });
            res.forEach((el) => {
              this.listIndikator.push({
                text: el.indikator_program,
                id: el.idindikator_program,
              });
            });
            res.forEach((el) => {
              this.listSasaranStrategis.push({
                text: el.sasaran_renstra,
                id: el.idsasaran_renstra,
              });
            });
            store.commit("snackbar/setSnack", {
              message: "Get Data Sinkronisasi Indikator Berhasil!",
              color: "success",
            });
          } else {
            store.commit("snackbar/setSnack", {
              message:
                "Get Data Sinkronisasi Gagal/Data Indikator Kosong. Silahkan periksa kembali di sistem E-Sakip!",
              color: "warning",
            });
          }
        })
        .finally(() => {
          this.loadingBtn.syncSakipProgram = false;
          this.$refs.syncProgramSatuanTarget.focus();
          this.$refs.syncProgramTargetBawah.focus();
          this.$refs.syncProgramIndikator.focus();
          this.$refs.syncProgramListSasaranStrategis.focus();
        })
        .catch((err) => {
          var error = err.message ? err.message : err.response.data.message;
          this.loadingEl = -1;
          store.commit("snackbar/setSnack", { message: error, color: "error" });
        });
    },

    syncSakipKegiatan() {
      this.loadingBtn.syncSakipKegiatan = true;
      let current = store.state.user.current;
      let user = store.state.user;
      var base_url_api = _g.getBaseURLAPIERK(current.year);
      const url_get_data = base_url_api + "v1/sync-skp-sakip-kegiatan";
      if (
        user.current.role.includes("PLT") ||
        user.current.role.includes("Kepala UPT Puskesmas") ||
        user.current.role.includes("PLH")
      ) {
        current.username = _g.overrideWithPLT(user.current.role);
      } else {
        current.username = user.current.username;
      }
      this.tempListSasaranStrategis = this.listSasaranStrategis;
      this.listSasaranStrategis = [];
      axios
        .post(url_get_data, {
          api: 1,
          nip: current.username,
        })
        .then((response) => {
          if (response.data.success) {
            let res = response.data.data.data;
            res.forEach((el) => {
              this.listItemIndikator.push(el);
            });
            res.forEach((el) => {
              this.listIndikator.push({
                text: el.indikator_kegiatan,
                id: el.idindikator_kegiatan,
              });
            });
            res.forEach((el) => {
              this.listSasaranStrategis.push({
                text: el.sasaran_renstra,
                id: el.idsasaran_renstra,
              });
            });
            store.commit("snackbar/setSnack", {
              message: "Get Data Sinkronisasi Indikator Berhasil!",
              color: "success",
            });
          } else {
            store.commit("snackbar/setSnack", {
              message:
                "Get Data Sinkronisasi Gagal/Data Indikator Kosong. Silahkan periksa kembali di sistem E-Sakip!",
              color: "warning",
            });
          }
        })
        .finally(() => {
          this.loadingBtn.syncSakipKegiatan = false;
          this.$refs.syncProgramSatuanTarget.focus();
          this.$refs.syncProgramTargetBawah.focus();
          this.$refs.syncProgramIndikator.focus();
          this.$refs.syncProgramListSasaranStrategis.focus();
        })
        .catch((err) => {
          var error = err.message ? err.message : err.response.data.message;
          this.loadingEl = -1;
          store.commit("snackbar/setSnack", { message: error, color: "error" });
        });
    },

    syncSakipSubKegiatan() {
      this.loadingBtn.syncSakipSubKegiatan = true;
      let current = store.state.user.current;
      let user = store.state.user;
      var base_url_api = _g.getBaseURLAPIERK(current.year);
      const url_get_data = base_url_api + "v1/sync-skp-sakip-sub-kegiatan";
      if (
        user.current.role.includes("PLT") ||
        user.current.role.includes("Kepala UPT Puskesmas") ||
        user.current.role.includes("PLH")
      ) {
        current.username = _g.overrideWithPLT(user.current.role);
      } else {
        current.username = user.current.username;
      }
      axios
        .post(url_get_data, {
          api: 1,
          nip: current.username,
        })
        .then((response) => {
          if (response.data.success) {
            let res = response.data.data.data;
            res.forEach((el) => {
              this.listItemIndikator.push(el);
            });
            res.forEach((el) => {
              this.listIndikator.push({
                text: el.indikator_subkegiatan,
                id: el.idindikator_subkegiatan,
              });
            });
            res.forEach((el) => {
              this.listSasaranStrategis.push({
                text: el.sasaran_renstra,
                id: el.idsasaran_renstra,
              });
            });
            store.commit("snackbar/setSnack", {
              message: "Get Data Sinkronisasi Indikator Berhasil!",
              color: "success",
            });
          } else {
            store.commit("snackbar/setSnack", {
              message:
                "Get Data Sinkronisasi Gagal/Data Indikator Kosong. Silahkan periksa kembali di sistem E-Sakip!",
              color: "warning",
            });
          }
        })
        .finally(() => {
          this.loadingBtn.syncSakipSubKegiatan = false;
          this.$refs.syncProgramSatuanTarget.focus();
          this.$refs.syncProgramTargetBawah.focus();
          this.$refs.syncProgramIndikator.focus();
          this.$refs.syncProgramListSasaranStrategis.focus();
        })
        .catch((err) => {
          var error = err.message ? err.message : err.response.data.message;
          this.loadingEl = -1;
          store.commit("snackbar/setSnack", { message: error, color: "error" });
        });
    },

    getImg(nip, ext) {
      if (nip) {
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip + "." + ext;
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl;
      }
      return "";
    },

    updateTableTahunan(val) {
      this.forceUpdate = false;
      this.tableValueTahunan = val ? val : this.tableValueTahunan;
      this.loadingTahunan = true;
      this.itemsTahunan = {};

      SKPService.getListPeriodeSKP().then((response) => {
        if (response.data.status == true) {
          for (let i = 0; i < response.data.data.length; i++) {
            const el = response.data.data[i];
            this.listPeriodeSKP.push(response.data.data[i]);
          }
          console.log(this.listPeriodeSKP);
        }
      });

      SKPService.getSKP({
        search: this.tableValueTahunan.search,
        row_per_page: this.tableValueTahunan.itemsPerPage,
        triwulan: "tahun",
        page: this.tableValueTahunan.page,
      })
        .then((response) => {
          // console.log('tahunan',response.data)
          this.itemsTahunan = response.data;
          // this.dataPegawaiEkspektasiPerilaku = response.data.data[0]
          // if(this.tahun >= 2022){
          //   this.dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'] = response.data.ekspektasi_pimpinan != undefined ? response.data.ekspektasi_pimpinan[0] : []
          // }
          this.eselon = response.data.eselon;
          this.jft = response.data.jft;
          this.input_skp_dibebaskan_by_nip =
            this.itemsTahunan.input_skp_dibebaskan_by_nip;
          console.log(this.itemsTahunan);
        })
        .finally(() => {
          this.loadingTahunan = false;
        });
    },

    updateTableDilaporkan(val) {
      this.forceUpdate = false;
      this.tableValuesDilaporkan = val ? val : this.tableValuesDilaporkan;
      this.loadingDilaporkan = true;
      this.itemsDilaporkan = {};
      SKPService.getSKPYangSudahDilaporkan({
        row_per_page: this.tableValuesDilaporkan.itemsPerPage,
        page: this.tableValuesDilaporkan.page,
      })
        .then((response) => {
          this.itemsDilaporkan = response.data;
        })
        .finally(() => {
          this.loadingDilaporkan = false;
        });
    },

    updateTablePengaturanSKP(val) {
      this.forceUpdate = false;
      this.tableValuesPengaturanSKP = val ? val : this.tableValuesPengaturanSKP;
      this.loadingPengaturanSKP = true;
      this.itemsPengaturanSKP = {};
      SKPService.getDataPengaturanSKP({
        row_per_page: this.tableValuesPengaturanSKP.itemsPerPage,
        page: this.tableValuesPengaturanSKP.page,
      })
        .then((response) => {
          this.itemsPengaturanSKP = response.data;
        })
        .finally(() => {
          this.loadingPengaturanSKP = false;
        });
    },

    updateBoth() {
      this.updateTableDilaporkan(),
        setTimeout(() => {
          this.updateTableTahunan();
        }, 500);
    },

    getPengaturan(new_) {
      this.isEditPengaturanSKP = false;
      this.loadingBtn.pengaturan = true;
      SKPService.getPengaturanSKP()
        .then((response) => {
          this.dialogPengaturanSKP = true;
          if (new_) {
            this.formPengaturan = _.clone(response.data.data);
            this.formPengaturan.periode_awal = null;
            this.formPengaturan.periode_akhir = null;
            this.formPengaturan.id = null;
          } else {
            this.formPengaturan = _.clone(response.data.data);
          }
        })
        .finally(() => {
          this.loadingBtn.pengaturan = false;
        });
    },

    async cariNIP(nip) {
      let dataNIP;
      dataNIP = await SKPService.getPegawaiByNip(nip).then((response) => {
        let res = response.data;
        return res.data;
      });
      return dataNIP;
    },

    simpanPengaturan() {
      if (this.$refs.formPengaturan.validate()) {
        this.loadingBtn.simpanPengaturan = true;
        this.formPengaturan.jabatan_nama_atasan_penilai =
          this.formPengaturan.jabatan_atasan_penilai;
        this.formPengaturan.jabatan_penilai =
          this.formPengaturan.jabatan_atasan_penilai;
        if (this.formPengaturan.triwulan == 6) {
          this.formPengaturan.lepas_jabatan = true;
        } else {
          this.formPengaturan.lepas_jabatan = false;
        }
        let state = null;
        SKPService.editPengaturanSKP(this.formPengaturan)
          .then((response) => {
            let res = response.data;
            if (res.success) {
              state = true;
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 200);
            } else {
              state = false;
              store.commit("snackbar/setSnack", {
                message: res.message,
                color: "error",
              });
            }
          })
          .finally(() => {
            this.dialogPengaturanSKP = state == true ? false : true;
            this.loadingBtn.simpanPengaturan = false;
          });
      }
    },

    editItemPengaturanSKP(item) {
      this.isEditPengaturanSKP = true;
      // this.formPengaturan = _.clone(item)
      this.formPengaturan.id = item.id;
      this.formPengaturan.nip_atasan = item.nip_atasan;
      this.formPengaturan.jenis_atasan = item.jenis_atasan;
      this.formPengaturan.nama_atasan = item.nama_atasan;
      this.formPengaturan.golongan_atasan =
        item.atasan.nm_pkt + ", " + item.atasan.nm_gol;
      this.formPengaturan.jabatan_atasan = item.jabatan_atasan;
      this.formPengaturan.unit_kerja_atasan = item.unit_kerja_atasan;
      this.formPengaturan.nip_atasan_penilai = item.nip_atasan_penilai;
      this.formPengaturan.jenis_atasan_penilai = item.jenis_atasan_penilai;
      this.formPengaturan.nama_atasan_penilai = item.nama_atasan_penilai;
      this.formPengaturan.golongan_atasan_penilai =
        item.atasan_penilai.nm_pkt + ", " + item.atasan_penilai.nm_gol;
      this.formPengaturan.jabatan_atasan_penilai = item.jabatan_atasan_penilai;
      this.formPengaturan.unit_kerja_atasan_penilai =
        item.unit_kerja_atasan_penilai;
      this.formPengaturan.triwulan = item.triwulan;
      this.formPengaturan.periode_awal = item.periode_awal;
      this.formPengaturan.periode_akhir = item.periode_akhir;
      if (item.periode_awal == 1 && item.periode_akhir == 3) {
        this.formPengaturan.triwulan = 1;
      } else if (item.periode_awal == 4 && item.periode_akhir == 6) {
        this.formPengaturan.triwulan = 2;
      } else if (item.periode_awal == 7 && item.periode_akhir == 9) {
        this.formPengaturan.triwulan = 3;
      } else if (item.periode_awal == 10 && item.periode_akhir == 12) {
        this.formPengaturan.triwulan = 4;
      } else if (item.periode_awal == 1 && item.periode_akhir == 12) {
        this.formPengaturan.triwulan = 5;
      } else {
        this.formPengaturan.triwulan = 6;
      }
      this.disabledPeriode(this.formPengaturan.triwulan);
      this.formPengaturan.jabatan_nama = item.jabatan_nama;
      this.formPengaturan.unit_kerja = item.unit_kerja;
      this.dialogPengaturanSKP = true;
    },

    deleteItemPengaturanSKP(item) {
      SKPService.hapusPengaturanSKP(item.id).then((response) => {
        let res = response.data;
        if (res.success) {
          store.commit("snackbar/setSnack", {
            message: res.message,
            color: "success",
          });
          this.updateTablePengaturanSKP();
        } else {
          store.commit("snackbar/setSnack", {
            message: res.message,
            color: "error",
          });
        }
      });
    },

    assignmentItem(item) {
      this.inputs_ = [];
      //inisiasi total value
      for (let i = 0; i < item.bawahan.length; i++) {
        var element = item.bawahan[i];
        element.sum_assignment = "-";
      }
      var nips = JSON.parse(item.nip_assignments);
      // var assignments = []
      // var values = [];
      // set disabled checkbox and button
      if (nips != null) {
        for (let j = 0; j < item.bawahan.length; j++) {
          const nip_ = item.bawahan[j].peg_nip;
          for (let i = 0; i < nips.length; i++) {
            const nip = nips[i];
            if (nip == nip_) {
              item.bawahan[j].checked = true;
              item.bawahan[j].disabled_assignment = true;
            }
          }
        }
      }
      //append existing data
      for (let j = 0; j < item.bawahan.length; j++) {
        const element = item.bawahan[j];
        var z = 0;
        if (element.checked) {
          if (item.skp_matriks_bawahan.length > 0) {
            for (let s = 0; s < item.skp_matriks_bawahan.length; s++) {
              for (let t = 0; t < item.skp_matriks_bawahan[s].length; t++) {
                const each = item.skp_matriks_bawahan[s][t];
                if (element.peg_nip == each.nip18) {
                  z++;
                  this.inputs_.push({
                    id: each.id,
                    number: s + 1,
                    rencana_kinerja_matriks: each.rencana_kinerja,
                    nip: each.nip18,
                    deleted: false,
                  });
                }
              }
            }
          }
          item.bawahan[j].sum_assignment = z;
        }
      }
      // this.inputs_.push(values)
      this.dataPegawai = item;
      this.dialogFormAssignmentSKP = true;
    },

    lampiranAngkaKreditItem(item) {
      this.rowDataAngkaKredit = [];
      this.no = 0;
      if (item.detail_angka_kredit != null) {
        for (let a = 0; a < item.detail_angka_kredit.length; a++) {
          const element = item.detail_angka_kredit[a];
          this.no += 1;
          this.rowDataAngkaKredit.push({
            id: element.id,
            no: this.no,
            butir: element.butir,
            output: element.output,
            angka_kredit: element.angka_kredit,
            nip: element.nip,
          });
        }
      }
      this.dataPegawai = item;
      this.dialogFormLampiranAngkaKreditSKP = true;
    },

    addRow() {
      this.no = this.rowData.length;
      this.no += 1;
      var my_object = {
        no: this.no,
        skema_pertanggungjawaban: "",
        dukungan_sumber_daya: "",
        konsekuensi: "",
      };
      this.rowData.push(my_object);
    },
    removeItem(item) {
      this.no -= 1;
      for (let i = 0; i < this.rowData.length; i++) {
        const el = this.rowData[i];
        if (el.no == item.no) {
          this.rowData.splice(i, 1);
        }
      }
      // re-indexing
      for (let i = 0; i < this.rowData.length; i++) {
        this.rowData[i].no = i + 1;
      }
      console.log(this.rowData);
    },

    simpanLampiranSKP() {
      if (this.rowData.length == 0) {
        store.commit("snackbar/setSnack", {
          message: "Maaf, Lengkapi dahulu data lampiran SKP anda.",
          color: "warning",
        });

        return;
      }
      this.loadingBtn.lampiran = true;
      var dsd = [];
      var sp = [];
      var k = [];
      this.rowData.forEach((el) => {
        dsd.push(el.dukungan_sumber_daya);
        sp.push(el.skema_pertanggungjawaban);
        k.push(el.konsekuensi);
      });
      var data = {
        dukungan_sumber_daya: JSON.stringify(dsd),
        skema_pertanggungjawaban: JSON.stringify(sp),
        konsekuensi: JSON.stringify(k),
      };
      SKPService.storeLampiranSKP(data)
        .then((response) => {
          let res = response.data;
          if (res.success) {
            store.commit("snackbar/setSnack", {
              message: res.message,
              color: "success",
            });
            this.dialogLampiranSKP = false;
          } else {
            store.commit("snackbar/setSnack", {
              message: res.message,
              color: "error",
            });
          }
          this.updateTableTahunan();
        })
        .finally(() => {
          this.loadingBtn.lampiran = false;
        });
    },

    tutupFormLampiranSKP() {
      this.rowData = [];
      this.updateLampiranSKP();
      this.dialogLampiranSKP = false;
      this.$emit("onClose", false);
    },

    updateLampiranSKP() {
      SKPService.getLampiranSKP().then((response) => {
        let res = response.data;
        let dsd =
          res.data[0] != undefined
            ? JSON.parse(res.data[0].dukungan_sumber_daya)
            : [];
        let sp =
          res.data[0] != undefined
            ? JSON.parse(res.data[0].skema_pertanggungjawaban)
            : [];
        let k =
          res.data[0] != undefined ? JSON.parse(res.data[0].konsekuensi) : [];
        let size = [dsd.length, sp.length, k.length];
        let max_size = Math.max(...size);
        for (let i = 0; i < max_size; i++) {
          this.rowData.push({
            no: i,
            dukungan_sumber_daya: dsd[i] != undefined ? dsd[i] : "",
            skema_pertanggungjawaban: sp[i] != undefined ? sp[i] : "",
            konsekuensi: k[i] != undefined ? k[i] : "",
          });
        }
      });
    },

    lihatFormEkspektasiPerilaku() {
      // this.readOnlyFormEkspektasi = true
      // this.dialogFormEkspektasiPerilaku = true
      // var current = store.state.user.data
      // var tahun = this.$store.getters["user/data"].tahun
      // SKPService.getEkspektasiPerilakuKerja(current.username, 'all', tahun).then(response=>{
      //   let res =response.data
      //   // this.dataPegawaiEkspektasiPerilaku = res.data[0]
      //   // this.dataPegawaiEkspektasiPerilaku = res.data
      //   if(this.tahun >= 2022){
      //     // this.dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'] = response.data.ekspektasi_pimpinan != undefined ? response.data.ekspektasi_pimpinan[0] : []
      //     let user = store.state.user
      //     this.dataPegawaiEkspektasiPerilaku = res.data
      //     this.dataPegawaiEkspektasiPerilaku.foto = user.data.data_asn.foto
      //     this.dataPegawaiEkspektasiPerilaku.nip = user.data.data_asn.nip
      //     this.dataPegawaiEkspektasiPerilaku.jabatan = user.data.data_asn.jabatan
      //     this.dataPegawaiEkspektasiPerilaku.nama = user.data.data_asn.nama
      //     this.dataPegawaiEkspektasiPerilaku.check = true
      //     // this.dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'].jenis = this.triwulan
      //   }
      //   // if(res.data[0] != undefined){
      //   //   this.dataEkspektasiBawahan = res.data[0]
      //   // }
      //   // this.dataEkspektasiBawahan.foto = peg.foto
      //   // this.dataEkspektasiBawahan.nip = peg.nip
      //   // this.dataEkspektasiBawahan.jabatan = peg.jabatan
      //   // this.dataEkspektasiBawahan.nama = peg.nama
      //   // this.dataEkspektasiBawahan.jenis = this.triwulan
      // }).finally(()=>{
      //   this.readOnlyFormEkspektasi = true
      //   this.dialogFormEkspektasiPerilaku = true
      //   // this.dataPegawaiBawahan = peg
      //   // this.dialogFormEkspektasiPerilaku = true
      //   // if(isUmpanBalik){
      //   //   this.loadingElUmpanBalik = -1
      //   //   this.$refs.formUmpanBalikEkspektasiPerilaku.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   // }else{
      //   //   this.loadingEl = -1
      //   //   this.$refs.formIsiEkspektasiPerilaku.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   // }
      //   // this.$nextTick(() => {
      //     // block: 'end' will scroll to top of element instead of bottom
      //     // this.$refs.formUmpanBalikEkspektasiPerilaku.$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   // });
      // })

      let current = store.state.user.current;
      let user = store.state.user;
      if (
        user.current.role.includes("PLT") ||
        user.current.role.includes("Kepala UPT Puskesmas") ||
        user.current.role.includes("PLH")
      ) {
        current.username = _g.overrideWithPLT(user.current.role);
      } else {
        current.username = user.current.username;
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year);
      const url_get_data = base_url_api + "v1/get-ekspektasi-perilaku";
      axios
        .post(url_get_data, {
          nip_atasan: current.username,
          nip: current.username,
          triwulan: "all",
          tahun: current.year,
        })
        .then((response) => {
          let res = response.data;
          if (this.tahun >= 2022) {
            let user = store.state.user;
            this.dataPegawaiEkspektasiPerilaku = res.data;
            this.dataPegawaiEkspektasiPerilaku.foto = user.data.data_asn.foto;
            this.dataPegawaiEkspektasiPerilaku.nip = user.data.data_asn.nip;
            this.dataPegawaiEkspektasiPerilaku.jabatan =
              user.data.data_asn.jabatan;
            this.dataPegawaiEkspektasiPerilaku.nama = user.data.data_asn.nama;
            this.dataPegawaiEkspektasiPerilaku.check = true;
          }
        })
        .finally(() => {
          this.readOnlyFormEkspektasi = true;
          this.dialogFormEkspektasiPerilaku = true;
        })
        .catch((err) => {
          var error = err.message ? err.message : err.response.data.message;
          this.loadingEl = -1;
          store.commit("snackbar/setSnack", { message: error, color: "error" });
        });
    },
    isiFormEkspektasiPerilaku() {
      this.readOnlyFormEkspektasi = false;
      this.dialogFormEkspektasiPerilaku = true;
      this.dialogFormEkspektasiPerilakuPersistent = true;
    },

    changeSKPAtasan() {
      this.tempSKPAtasan = {};
    },

    changePeriode() {
      this.disabledPeriode(this.formPengaturan.triwulan);
      switch (this.formPengaturan.triwulan) {
        case 1:
          this.formPengaturan.periode_awal = 1;
          this.formPengaturan.periode_akhir = 3;
          break;
        case 2:
          this.formPengaturan.periode_awal = 4;
          this.formPengaturan.periode_akhir = 6;
          break;
        case 3:
          this.formPengaturan.periode_awal = 7;
          this.formPengaturan.periode_akhir = 9;
          break;
        case 4:
          this.formPengaturan.periode_awal = 10;
          this.formPengaturan.periode_akhir = 12;
          break;
        case 5:
          this.formPengaturan.periode_awal = 1;
          this.formPengaturan.periode_akhir = 12;
          break;
        default:
          if (!this.isEditPengaturanSKP) {
            this.formPengaturan.periode_awal = null;
            this.formPengaturan.periode_akhir = null;
          }
          break;
      }
    },

    cariAtasan() {
      var nip = this.formPengaturan.nip_atasan;
      SKPService.getPegawaiByNip(nip).then((response) => {
        let data = response.data.data;
        this.nip1 = _.clone(data);
        this.formPengaturan.nama_atasan = data.peg_nama;
        this.formPengaturan.golongan_atasan = data.nm_pkt + ", " + data.nm_gol;
        this.formPengaturan.jabatan_atasan = data.jabatan_nama_atasan;
        this.formPengaturan.unit_kerja_atasan = data.unit_kerja;
      });
    },

    cariAtasanPenilai() {
      var nip = this.formPengaturan.nip_atasan_penilai;
      SKPService.getPegawaiByNip(nip).then((response) => {
        let data = response.data.data;
        this.nip2 = _.clone(data);
        this.formPengaturan.nama_atasan_penilai = data.peg_nama;
        this.formPengaturan.golongan_atasan_penilai =
          data.nm_pkt + ", " + data.nm_gol;
        this.formPengaturan.jabatan_atasan_penilai = data.jabatan_nama_atasan;
        this.formPengaturan.unit_kerja_atasan_penilai = data.unit_kerja;
      });
    },

    disabledPeriode(val) {
      if (val == 6) {
        this.disabledPeriode_ = false;
      } else {
        this.disabledPeriode_ = true;
      }
    },

    getAtasan() {
      this.loadGetAtasan = true;
      SKPService.getAtasan()
        .then((response) => {
          let data = response.data;
          this.nip1 = _.clone(data);
          this.formPengaturan.nip_atasan = data.peg_nip;
          this.formPengaturan.nama_atasan = data.peg_nama;
          this.formPengaturan.golongan_atasan =
            data.nm_pkt + ", " + data.nm_gol;
          this.formPengaturan.jabatan_atasan = data.jabatan_nama_atasan;
          this.formPengaturan.unit_kerja_atasan = data.unit_kerja;
        })
        .finally(() => {
          this.loadGetAtasan = false;
        });
    },

    getAtasanPenilai() {
      this.loadGetAtasanPenilai = true;
      SKPService.getAtasanPenilai()
        .then((response) => {
          let data = response.data;
          this.nip2 = _.clone(data);
          this.formPengaturan.nip_atasan_penilai = data.peg_nip;
          this.formPengaturan.nama_atasan_penilai = data.peg_nama;
          this.formPengaturan.golongan_atasan_penilai =
            data.nm_pkt + ", " + data.nm_gol;
          this.formPengaturan.jabatan_atasan_penilai = data.jabatan_nama_atasan;
          this.formPengaturan.unit_kerja_atasan_penilai = data.unit_kerja;
        })
        .finally(() => {
          this.loadGetAtasanPenilai = false;
        });
    },

    clearPenilai() {
      this.formPengaturan.jenis_atasan = null;
      this.formPengaturan.nip_atasan = null;
      this.formPengaturan.nama_atasan = null;
      this.formPengaturan.golongan_atasan = null;
      this.formPengaturan.jabatan_atasan = null;
      this.formPengaturan.unit_kerja_atasan = null;
    },

    clearAtasanPenilai() {
      this.formPengaturan.jenis_atasan_penilai = null;
      this.formPengaturan.nip_atasan_penilai = null;
      this.formPengaturan.nama_atasan_penilai = null;
      this.formPengaturan.golongan_atasan_penilai = null;
      this.formPengaturan.jabatan_atasan_penilai = null;
      this.formPengaturan.unit_kerja_atasan_penilai = null;
    },

    changeTarget() {
      var id = this.formEdit.indikator_kuantitas;
      this.listItemIndikator.forEach((el) => {
        if (el.idindikator_program != undefined) {
          if (el.idindikator_program == id) {
            // this.formEdit.skp_atasan              = el.sasaran_renstra
            // this.formEdit.indikator_kuantitas     = el.indikator_program
            // this.formEdit.rencana_kinerja         = el.sasaran_program
            this.formEdit.target_bawah = el.target;
            this.formEdit.satuan_target = el.satuan;
            this.formEdit.id_sasaran_strategis = el.idsasaran_renstra;
            this.formEdit.i_idsasaran_renstra = el.idsasaran_renstra;
            this.formEdit.sasaran_kinerja_tahunan = el.sasaran_program;
            this.formEdit.i_idprogram_renstra = el.idprogram_renstra;
            this.formEdit.i_idprogram_rpjmd = el.idprogram_rpjmd;
            this.formEdit.i_kdprogram = el.kdprogram;
            this.formEdit.i_idindikator_program = el.idindikator_program;
            this.formEdit.level = "program";
            this.formEdit.id_tahun = el.idtahun;
          }
        }
        if (el.idindikator_kegiatan != undefined) {
          if (el.idindikator_kegiatan == id) {
            // this.formEdit.skp_atasan              = el.sasaran_renstra
            // this.formEdit.indikator               = el.indikator_kegiatan
            // this.formEdit.indikator_kuantitas     = el.indikator_kegiatan
            // this.formEdit.rencana_kinerja         = el.indikator_kegiatan
            this.formEdit.id_tahun = el.idtahun;
            this.formEdit.i_idsasaran_renstra = el.idsasaran_renstra;
            this.formEdit.sasaran_kinerja_tahunan = el.sasaran_renstra;
            this.formEdit.i_idprogram_renstra = el.idprogram_renstra;
            this.formEdit.satuan_target = el.satuan;
            this.formEdit.i_kdkegiatan = el.kdkegiatan;
            this.formEdit.i_idkegiatan = el.idkegiatan;
            this.formEdit.i_idindikator_kegiatan = el.idindikator_kegiatan;
            this.formEdit.level = "kegiatan";
            this.formEdit.target_bawah = el.target;
          }
        }
        if (el.idindikator_subkegiatan != undefined) {
          if (el.idindikator_subkegiatan == id) {
            // this.formEdit.skp_atasan          = el.sasaran_program
            // this.formEdit.indikator               = el.indikator_subkegiatan
            // this.formEdit.indikator_kuantitas     = el.indikator_subkegiatan
            // this.formEdit.rencana_kinerja         = el.sasaran_subkegiatan
            this.formEdit.satuan_target = el.satuan;
            this.formEdit.level = "subkegiatan";
            this.formEdit.sasaran_kinerja_tahunan = el.sasaran_subkegiatan;
            this.formEdit.i_idsasaran_renstra = el.idsasaran_renstra;
            this.formEdit.i_idprogram_renstra = el.idprogram_renstra;
            this.formEdit.i_idkegiatan = el.idkegiatan;
            this.formEdit.i_kdkegiatan = el.kdkegiatan;
            this.formEdit.i_idsubkegiatan = el.idsubkegiatan;
            this.formEdit.i_kdsubkegiatan = el.kdsubkegiatan;
            this.formEdit.i_idindikator_subkegiatan =
              el.idindikator_subkegiatan;
            this.formEdit.target_bawah = el.target;
            this.formEdit.id_tahun = el.idtahun;
          }
        }
        let satuan_ = null;
        satuan_ = this.satuanSKP.find((x) => x === this.formEdit.satuan_target);
        if (satuan_ == null) {
          satuan_ = this.satuanSKP.find(
            (x) => x === this.formEdit.satuan_target.toLowerCase()
          );
        }
        this.formEdit.satuan_target = satuan_;
      });
    },

    skpIki() {
      var skp_iki = this.formEdit.is_skp_iki;
      if (this.eselon == 2 || this.eselon == 3 || this.eselon == 4) {
        if (this.dialogEditSKPPermenpan6) {
          if (!skp_iki) {
            this.tempIndikator.push({
              indikator: this.formEdit.indikator_kuantitas,
              target_bawah: this.formEdit.target_bawah,
              target_atas: this.formEdit.target_atas,
            });
            this.tempModel.id_sasaran_strategis =
              this.formEdit.id_sasaran_strategis;
            this.tempModel.target_bawah = this.formEdit.target_bawah;
            this.tempModel.satuan_target = this.formEdit.satuan_target;
            this.tempListSasaranStrategis = this.listSasaranStrategis;
            this.listSasaranStrategis = [];
            this.formEdit.is_sakip = true;
          } else {
            this.formEdit.indikator_kuantitas = this.tempIndikator[0].indikator;
            this.formEdit.target_bawah = this.tempIndikator[0].target_bawah;
            this.formEdit.target_atas = this.tempIndikator[0].target_atas;
            this.listSasaranStrategis = this.tempListSasaranStrategis;
            this.formEdit.id_sasaran_strategis =
              this.tempModel.id_sasaran_strategis;
            this.formEdit.target_bawah = this.tempModel.target_bawah;
            this.formEdit.satuan_target = this.tempModel.satuan_target;
            this.formEdit.is_sakip = false;
          }
        }
      } else {
        this.formEdit.is_skp_iki = skp_iki;
        this.disabledForm = skp_iki;
      }
    },

    skpSyncEsakip() {
      this.formEdit.is_skp_iki = true;
      var is_sakip = this.formEdit.is_sakip;
      if (this.eselon == 2 || this.eselon == 3 || this.eselon == 4) {
        if (this.dialogEditSKPPermenpan6) {
          if (!is_sakip) {
            this.tempIndikator.push({
              indikator: this.formEdit.indikator_kuantitas,
              target_bawah: this.formEdit.target_bawah,
              target_atas: this.formEdit.target_atas,
            });
            this.tempModel.id_sasaran_strategis =
              this.formEdit.id_sasaran_strategis;
            this.tempModel.target_bawah = this.formEdit.target_bawah;
            this.tempModel.satuan_target = this.formEdit.satuan_target;
            this.tempListSasaranStrategis = this.listSasaranStrategis;
            this.listSasaranStrategis = [];
            // this.formEdit.is_sakip = true
          } else {
            this.formEdit.indikator_kuantitas = this.tempIndikator[0].indikator;
            this.formEdit.target_bawah = this.tempIndikator[0].target_bawah;
            this.formEdit.target_atas = this.tempIndikator[0].target_atas;
            this.listSasaranStrategis = this.tempListSasaranStrategis;
            this.formEdit.id_sasaran_strategis =
              this.tempModel.id_sasaran_strategis;
            this.formEdit.target_bawah = this.tempModel.target_bawah;
            this.formEdit.satuan_target = this.tempModel.satuan_target;
            // this.formEdit.is_sakip = false
          }
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.label {
  font-size: 12px;
}
.row + .row {
  margin-top: 0px;
}
</style>
