<template>
<div>
  <!-- penambahan additional text atau menu -->
  <slot name="top"></slot>

  <!-- toolbar -->
  <v-toolbar flat class="pa-0 my-2" style="border-bottom:1px solid #eee">
    <slot name="header">
        <v-toolbar-title v-if="settings.enableHeader && settings.title">{{settings.title}}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical v-if="settings.enableHeader && settings.title"></v-divider>
        <slot name="prepend-header"></slot>
        <v-text-field single-line hide-details style="max-width:300px" :label="settings.searchLabel" append="mdi-magnify" append-icon="mdi-magnify" v-model="search" v-if="settings.enableHeader"></v-text-field>
        <v-spacer></v-spacer>
        <slot name="append-header"></slot>
    </slot>
  </v-toolbar>

  <v-progress-linear
      indeterminate
      color="pink"
      v-if="dataLoading"
  ></v-progress-linear>
  <v-data-table
    :headers="headers"
    :items="items"
    :server-items-length="totalItems"
    :page.sync="page"
    :items-per-page.sync="itemsPerPage"
    :loading="loading"
    :footer-props="{'items-per-page-options':[5,10,15]}"
    :hide-default-footer="hideFooter"
  >
		<template v-slot:item.nomer="props">
			{{ props.index+1 }}
		</template>
		<template v-slot:item.periode_skp="{item}">
			{{ item.periode_skp}}
		</template>
		<template v-slot:item.nip="{item}">
			<br>
			<b>{{ item.nip }} - {{ item.nama }}</b><br><br>
				Jabatan: {{ item.jabatan_nama }}<br><br>
				Unit Kerja: {{ item.unit_kerja }}
			<br><br>
		</template>
		<template v-slot:item.nip_atasan="{item}">
			<br>
			<b>{{ item.nip_atasan }} - {{ item.nama_atasan }}</b><br><br>
				Jabatan: {{ item.jabatan_nama_atasan }}<br><br>
				Unit Kerja: {{ item.unit_kerja_atasan }}
			<br><br>
		</template>
		<template v-slot:item.nip_atasan_penilai="{item}">
			<br>
			<div v-if="item.role == 'asisten'">
					<b>Nama: {{ item.nama_atasan_penilai }}</b><br><br>
			</div>
			<div v-else>
					<b>{{ item.nip_atasan_penilai }} - {{ item.nama_atasan_penilai }}</b><br><br>
			</div>
			<!-- Jabatan: {{ item.jabatan_nama_atasan_penilai }}<br><br> -->
			Jabatan: {{ item.jabatan_atasan_penilai }}<br><br>
			Unit Kerja: {{ item.unit_kerja_atasan_penilai }}
			<br><br>
		</template>

    <!-- tombol aksi -->
    <template v-slot:item.aksi="{ item }">
      <slot name="append-actions" :item="item"></slot>

      <v-btn x-small color="info" class="ma-1" @click="detailItem(item)" v-if="settings.enableDetail" >
        <v-icon  title="detail">
          mdi-magnify
        </v-icon>
        Detail
      </v-btn>

      <v-btn x-small color="secondary" class="ma-1" @click="editItemPengaturanSKP(item)" v-if="enableEditRule(item)" >
        <v-icon x-small left title="ubah data">
          mdi-pencil
        </v-icon>
        Ubah
      </v-btn>

      <v-btn x-small color="error" class="ma-1" @click="deleteItemPengaturanSKP(item)" v-if="enableDeleteRule(item)" >
        <v-icon x-small left title="hapus">
          mdi-delete
        </v-icon>
        Hapus
      </v-btn>

    </template>
  </v-data-table>

  <!-- detail dialog -->
  <v-dialog v-model="detailDialog" max-width="700px" v-if="detailData">
    <v-card >
      <v-card-title class="pa-0">
        <v-toolbar flat>
          <v-toolbar-title>{{settings.detailTitle}} <span class="font-weight-medium" ><q>{{detailData['Nama']}}</q> </span> </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="detailDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        <loading v-if="detailLoading"></loading>
        <div v-else>
          <table class="text-body-1 font-weight-normal">
            <tr v-for="(v,k,i) in detailData" :key="i">
              <td v-if="!k.match(/noDisplay.*/)" class="px-5 py-2">{{k}}</td>
              <td v-else></td>
              <td v-html="v"></td>
            </tr>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- delete dialog -->
  <v-dialog v-model="removeDialog" max-width="500px">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar flat >
          <v-toolbar-title>Hapus</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="removeDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        Hapus {{deletedItem[settings.deleteTitleKey]}} ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="red" @click="remove()">hapus</v-btn>
        <v-btn outlined color="blue" @click="removeDialog=false">batal</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import Loading from '@/components/base/Loading'

export default {
  name: 'Crud',
  components:{
    Loading,
  },

  props: {
    headers: Array,
    detailData: Object,
    crudSettings: Object,
    forceUpdateTable: Boolean,
    dataLoading:Boolean,
    hideFooter:{
      type: Boolean,
      default: false
    },
    responseData: {
      type: Object,
      default: function() {
        return {
          data: []
        }
      }
    },
    tabTriwulan: Object
  },

  data: () => ({
    dialog: false,
    detailDialog: false,
    detailLoading:false,
    removeDialog:false,
    editedIndex: -1,
    page: 1,
    itemsPerPage: 10,
    // totalItems:100,
    loading: false,
    search: '',
    deletedItem: {},
    editedItem: {},
    defaultItem: {},
    forceUpdateFlag: false,
    defaultSettings:{
      title: 'CRUD',
      detailTitle: 'Detail',
      enableHeader:true,
      enableDetail:true,
      enableCreate:true,
      enableEdit:true,
      enableDelete:true,
      enableFeedback:false,
      deleteTitleKey:'title',
      searchLabel:'Cari'
    },
    classAssignments: ''
  }),

  computed: {

    settings(){
      let s = {...this.defaultSettings, ...this.crudSettings}
      return s
    },

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

    items() {
      return this.responseData.data
    },

    totalItems() {
      return this.responseData.total_keseluruhan * 1
    },

    filterList(){
      return []
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    page(val) {
      console.log(val)
      this.loading = true
      this.$emit('onTableChange', {
        search: this.search,
        itemsPerPage: this.itemsPerPage,
        page: this.page
      })
    },

    forceUpdateTable(val){
      console.log('force update flag',  val)
      if (val && this.forceUpdateFlag){
        this.page = 1
        this.$emit('onTableChange', {
          search: this.search,
          itemsPerPage: this.itemsPerPage,
          page: this.page
        })
        this.forceUpdateFlag = false
      } else if( val == false) {
          this.forceUpdateFlag = true
      }
    },

    responseData(val) {
      console.log('responseData',val)
      this.loading = false
    },

    detailData(val){
      console.log('detailData', val)
      this.detailLoading=false
    }
  },

  created() {
    this.initialize()
  },

  mounted() {
    // watch multiple values
    this.$watch(vm => [vm.itemsPerPage, vm.search], val => {
      this.page = 1
      this.$emit('onTableChange', {
        search: this.search,
        itemsPerPage: this.itemsPerPage,
        page: this.page
      }, {
        immediate: true,
        deep: false
      })
    })
  },

  methods: {
    currentTriwulan(){
      let date = new Date()
      let month = date.getMonth() + 1;
      let triwulan = (Math.ceil(month / 3))
      if(this.tabTriwulan == triwulan)return true; else if(triwulan == 1) return true; else return false
    },
    enableEditRule(item){
      return this.settings.enableEdit && !item.is_sakip && ![1,4].includes(item.validasi)
    },

    enableDeleteRule(item){
      return this.settings.enableDelete && !item.is_sakip && ![1,4].includes(item.validasi)
    },

    filterWaktu(item){
      let waktu = item.waktu
      let satuan = item.satuan_waktu

      if (!isNaN(waktu)){
        return waktu + " " + satuan
      }else if(waktu.search('null') > -1 ){
        return '-'
      }
      return waktu
    },

    initialize() {},

    detailItem(item) {
      this.detailDialog = true
      this.detailLoading = true
      this.$emit('onDetailClick', item.id)
    },

    editItemPengaturanSKP(item) {
      this.$emit('onEditClick', item)
    },

    deleteItemPengaturanSKP(item) {
      this.deletedItem = Object.assign({}, item)
      console.log(this.deletedItem)
      this.removeDialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    remove(){
      this.$emit('onDeleteClick', this.deletedItem)
      this.removeDialog =false
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
        this.$emit('onEditClick',this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
        this.$emit('onCreateClick', this.editedItem)
      }
      this.close()
    },

  },
}
</script>

<style lang="css" scoped>
</style>
